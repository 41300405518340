import http from '@core/services/Http';

export const apiUploadMonitoring = async (payload: FormData): Promise<void> => {
  await http.post('monitoring', payload, {
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  });
};
