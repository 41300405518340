import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IAlbum } from '@core/models/media';
import http from '@core/services/Http';
import { ENameSpaces } from '@core/store/constants';
import { IGenericMetaState, TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { setFulfilledMetaState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

interface IParams {
  limit: number;
  offset: number;
}

interface IAlbumsState {
  data: IAlbum[];
  fetchedAll: boolean;
  meta: IGenericMetaState;
  params: IParams;
}

const initialState: IAlbumsState = {
  data: [],
  fetchedAll: false,
  meta: initialMeta,
  params: {
    limit: 1,
    offset: 0,
  },
};

export const fetchAlbums = createAsyncThunk<IAlbum[], IParams>(
  `${ENameSpaces.ALBUMS}/fetch`, async ({ limit, offset }, { rejectWithValue }) => {
    try {
      const { data } = await http.get<IAlbum[]>(`/media-bank-public/album?limit=${limit}&offset=${offset}`);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchMoreAlbums = createAsyncThunk<IAlbum[], void>(
  `${ENameSpaces.ALBUMS}/fetchMore`, async (arg, { getState, rejectWithValue }) => {
    try {
      const rootState = getState() as TRootState;
      const { offset, limit } = rootState[ENameSpaces.ALBUMS].params;
      const { data } = await http.get<IAlbum[]>(
        `/media-bank-public/album?limit=${limit}&offset=${offset + limit}`,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const albumsSlice = createSlice({
  name: ENameSpaces.ALBUMS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAlbums.pending, setPendingState);
    builder.addCase(fetchAlbums.rejected, setRejectedState);
    builder.addCase(fetchAlbums.fulfilled, (state, { payload, meta }) => {
      state.data = payload;
      state.params = meta.arg;
      state.fetchedAll = payload.length < meta.arg.limit;

      setFulfilledMetaState(state);
    });

    builder.addCase(fetchMoreAlbums.pending, setPendingState);
    builder.addCase(fetchMoreAlbums.rejected, setRejectedState);
    builder.addCase(fetchMoreAlbums.fulfilled, (state, { payload }) => {
      state.data = [...state.data, ...payload];
      state.params.offset += state.params.limit;
      state.fetchedAll = payload.length < state.params.limit;

      setFulfilledMetaState(state);
    });
  },
});

type TSliceState = ReturnType<typeof albumsSlice.reducer>;

export const selectAlbums = (rootState: TRootState): TSliceState => rootState[ENameSpaces.ALBUMS];

export default albumsSlice.reducer;
