import http from '@core/services/Http';

import { IFetchUpdateFileParams, IFileListApiParams, TFileListApiModel } from './types';

export const apiFetchFilesList = async (params: IFileListApiParams): Promise<TFileListApiModel> => {
  const { data } = await http.get<TFileListApiModel>('file', params);
  return data;
};

export const apiUploadMaterialFiles = async (payload: FormData): Promise<void> => {
  await http.post('file', payload, {
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
    },
  });
};

export const apiFetchUpdateFile = async (params: IFetchUpdateFileParams): Promise<void> => {
  await http.put<void>(`file/${params.id}`, params);
};

export const apiFetchDeleteFile = async (id: number): Promise<void> => {
  await http.delete(`file/${id}`);
};
