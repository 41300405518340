import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAlbum } from '@core/models/media';
import http from '@core/services/Http';
import { createAlbumMaterials, deleteAlbumMaterials, prepareAlbumPayload, updateAlbumMaterials } from '@core/services/media/album';
import { apiUpdateAlbum } from '@core/services/media/api';
import { IUpdateAlbumArg } from '@core/services/media/types';
import { splitAttachments } from '@core/services/media/utils';
import { ENameSpaces } from '@core/store/constants';

import { IAlbumFormValues } from '../components/AlbumForm/types';

export const createAlbum = createAsyncThunk<void, IAlbumFormValues>(
  `${ENameSpaces.MEDIA}/create`,
  async ({ attachments, ...formValues }, { rejectWithValue }) => {
    try {
      const { data: album } = await http.post<IAlbum>(
        'media-bank/album',
        prepareAlbumPayload({ ...formValues, isPublic: true }),
      );

      await createAlbumMaterials(
        album.id,
        attachments.map((attachment, index) => ({
          weight: index,
          file: attachment as File,
        })),
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateAlbum = createAsyncThunk<void, IUpdateAlbumArg>(
  `${ENameSpaces.MEDIA}/update`,
  async (
    { formValues: { attachments, ...formValues }, album },
    { rejectWithValue },
  ) => {
    try {
      const { newFiles, oldFiles } = splitAttachments(attachments);

      await Promise.allSettled([
        apiUpdateAlbum(album.id, prepareAlbumPayload(formValues)),
        deleteAlbumMaterials(album, oldFiles),
        updateAlbumMaterials(album.id, attachments),
        createAlbumMaterials(album.id, newFiles),
      ]);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
