import { IAlbum, IMaterial, IMaterialFile } from '@core/models/media';

import { IAlbumFormValues } from '@features/adminPanel/components/Media/components/AlbumForm/types';
import { TAttachment } from '@features/adminPanel/components/Media/components/AttachMedia/types';
import { isMaterialFile } from '@features/adminPanel/components/Media/utils';

import { apiDeleteMaterial, apiUpdateMaterial } from './api';
import { INewFile } from './types';
import { createMaterial } from './utils';

export const prepareAlbumPayload = (
  formValues: Omit<IAlbumFormValues, 'attachments'>,
) => ({
  ...formValues,
  tags: formValues.tags.reduce((acc, { name }) => {
    if (name) {
      acc.push(name);
    }

    return acc;
  }, [] as string[]),
});

export const createAlbumMaterials = (
  albumId: number,
  files: INewFile[],
) => Promise.allSettled(
  files.map((attachment) => createMaterial(attachment, albumId)),
);

export const updateAlbumMaterials = (
  albumId: number,
  attachments: TAttachment[],
) => {
  const materialsToUpdate = attachments.reduce((acc, attachment, index) => {
    if (!isMaterialFile(attachment)) {
      if (attachment instanceof File || attachment.weight === index) {
        return acc;
      }

      acc.push({ ...attachment, weight: index });
    }

    return acc;
  }, [] as IMaterial[]);

  return Promise.allSettled(
    materialsToUpdate.map(
      (material) => apiUpdateMaterial(
        material.id,
        {
          ...material,
          fileId: material.file.id,
          tags: material.tags?.map((tag) => tag.name) || [],
          albumId,
        },
      ),
    ),
  );
};

export const deleteAlbumMaterials = (
  album: IAlbum,
  currentMaterialFiles: IMaterialFile[],
) => {
  const materialsToDelete = album.materials.filter(
    ({ file: { id } = {} }) => !currentMaterialFiles.find(
      (attachment) => attachment.id === id,
    ),
  );

  return Promise.allSettled(
    materialsToDelete.map(({ id }) => apiDeleteMaterial(id)),
  );
};
