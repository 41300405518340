import jwtDecode from 'jwt-decode';

import { EUserRoles } from '@core/constants/userRoles';
import { EKeysJWT } from '@core/services/Auth/constants';
import {
  addAuthorizationInterceptor,
} from '@core/services/Http/interceptors/authorizationInterceptor';

import { apiRefreshJWT } from '@features/authentication/api';
import { IDecodedJWT, IUser } from '@features/authentication/models';

export const parseUser = (jwt: string): IUser => {
  const decodedJWT: IDecodedJWT = jwtDecode(jwt);

  return {
    id: decodedJWT.id,
    login: decodedJWT.login,
    role: decodedJWT.role,
    email: decodedJWT.email,
    name: decodedJWT.name,
    patronymic: decodedJWT.patronymic,
    surname: decodedJWT.surname,
    region: decodedJWT.region,
    position: decodedJWT.position,
  };
};

export const getJWT = (jwtKey: EKeysJWT) => localStorage.getItem(jwtKey);

export const saveJWT = (jwtAccess: string, jwtRefresh: string): void => {
  if (jwtAccess) {
    localStorage.setItem(EKeysJWT.ACCESS, jwtAccess);
  }

  if (jwtRefresh) {
    localStorage.setItem(EKeysJWT.REFRESH, jwtRefresh);
  }
};

export const removeJWT = (): void => {
  localStorage.removeItem(EKeysJWT.ACCESS);
  localStorage.removeItem(EKeysJWT.REFRESH);
};

export const authorize = (jwtAccess: string, jwtRefresh: string): void => {
  addAuthorizationInterceptor(jwtAccess);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  refreshByTimeout(jwtAccess, jwtRefresh);
};

export const logout = (): void => {
  removeJWT();
  window.location.reload();
};

export function isExpired(jwt: string): boolean {
  try {
    const decodedJWT: IDecodedJWT = jwtDecode(jwt);
    // exp - token expiration time in seconds, Date.now () returns milliseconds
    return decodedJWT.exp * 1000 < Date.now();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('wrong JWT');
    removeJWT();
    return true;
  }
}

export const checkPermissions = (user: IUser | null, roles: EUserRoles[] | undefined): boolean => {
  if (!roles) return true;
  return Boolean(user && roles.includes(user.role));
};

export const isRegionalUser = (user: IUser | null) : boolean => {
  if (!user) return false;
  return user.region.id !== 1;
};

export function refreshByTimeout(accessToken: string, refreshToken: string): void {
  const decodedAccess: IDecodedJWT = jwtDecode(accessToken);
  const delay = 10000;
  // exp - token expiration time in seconds, Date.now () returns milliseconds
  const accessTokenExpiresIn = decodedAccess.exp * 1000 - Date.now() - delay;

  setTimeout(async () => {
    try {
      const { access } = await apiRefreshJWT({ token: refreshToken });
      authorize(access, refreshToken);
    } catch (err) {
      logout();
    }
  }, accessTokenExpiresIn);
}
