import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { getFulfilledMeta, getPendingMeta, getRejectedMeta } from '@core/store/utils/stateSetters';

import {
  apiFetchAntiCrisisMonitoring, apiFetchAntiCrisisMonitoringHousingPolicy,
} from '@features/antiCrisisMonitoring/store/api';
import {
  EAntiCrisisMonitoringTables,
  IAntiCrisisMonitoringData,
  IAntiCrisisMonitoringHousingPolicy,
  IAntiCrisisMonitoringState,
} from '@features/antiCrisisMonitoring/store/types';
import { mapTable } from '@features/dynamicTable/components/DesktopView/mappers';
import { mapMobileData } from '@features/dynamicTable/components/MobileView/mappers';

export const fetchAntiCrisisMonitoring = createAsyncThunk<Partial<IAntiCrisisMonitoringData>, EAntiCrisisMonitoringTables>(
  'antiCrisisMonitoring/fetch',
  async (tableKey, { rejectWithValue }) => {
    try {
      const data = await apiFetchAntiCrisisMonitoring({ family: tableKey });

      return {
        family: data.family,
        name: data.name,
        date: data.date,
        footnotes: data.footnotes,
        desktopData: mapTable(data),
        mobileData: mapMobileData(data),
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchAntiCrisisMonitoringHousingPolicy = createAsyncThunk<IAntiCrisisMonitoringHousingPolicy, void>(
  'antiCrisisMonitoring/fetchHousingPolicy',
  async (_, { rejectWithValue }) => {
    try {
      const data = await apiFetchAntiCrisisMonitoringHousingPolicy();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getInitialData = (tableKey: EAntiCrisisMonitoringTables): IAntiCrisisMonitoringData => ({
  family: tableKey,
  name: null,
  date: null,
  footnotes: [],
  desktopData: null,
  mobileData: null,
});

const initialState: IAntiCrisisMonitoringState = {
  data: {
    ...Object.values(EAntiCrisisMonitoringTables)
      .reduce((result, tableKey) => ({
        ...result,
        [tableKey]: getInitialData(tableKey),
      }), {}) as IAntiCrisisMonitoringState['data'],
    housingPolicy: null,
  },
  meta: {
    ...Object.values(EAntiCrisisMonitoringTables)
      .reduce((result, tableKey) => ({
        ...result,
        [tableKey]: initialMeta,
      }), {}) as IAntiCrisisMonitoringState['meta'],
    housingPolicy: initialMeta,
  },
};

export const antiCrisisMonitoringSlice = createSlice({
  name: 'antiCrisisMonitoring',
  initialState,
  reducers: {
    resetState(state, action: PayloadAction<EAntiCrisisMonitoringTables>) {
      const tableKey = action.payload;
      state.data[tableKey] = getInitialData(tableKey);
      state.meta[tableKey] = initialMeta;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAntiCrisisMonitoring.pending, (state, action) => {
      const tableKey = action.meta.arg;

      state.meta = {
        ...state.meta,
        [tableKey]: getPendingMeta(),
      };
    });

    builder.addCase(fetchAntiCrisisMonitoring.fulfilled, (state, action) => {
      const tableKey = action.meta.arg;

      state.meta = {
        ...state.meta,
        [tableKey]: getFulfilledMeta(),
      };

      state.data = {
        ...state.data,
        [tableKey]: action.payload,
      };
    });

    builder.addCase(fetchAntiCrisisMonitoring.rejected, (state, action) => {
      const tableKey = action.meta.arg;

      state.meta = {
        ...state.meta,
        [tableKey]: getRejectedMeta(),
      };
    });

    builder.addCase(fetchAntiCrisisMonitoringHousingPolicy.pending, (state) => {
      state.meta.housingPolicy = getPendingMeta();
    });

    builder.addCase(fetchAntiCrisisMonitoringHousingPolicy.fulfilled, (state, action) => {
      state.data.housingPolicy = action.payload;
      state.meta.housingPolicy = getFulfilledMeta();
    });

    builder.addCase(fetchAntiCrisisMonitoringHousingPolicy.rejected, (state) => {
      state.meta.housingPolicy = getRejectedMeta();
    });
  },
});

export const selectAntiCrisisMonitoringData = createSelector(
  [
    (state: TRootState) => state.antiCrisisMonitoring,
    (state: TRootState, tableKey: EAntiCrisisMonitoringTables) => tableKey,
  ],
  (state: IAntiCrisisMonitoringState, tableKey: EAntiCrisisMonitoringTables) => {
    const { data, meta } = state;
    return {
      data: data[tableKey],
      meta: meta[tableKey],
    };
  },
);

export const selectAntiCrisisMonitoringHousingPolicy = (state: TRootState) => ({
  data: state.antiCrisisMonitoring.data.housingPolicy,
  meta: state.antiCrisisMonitoring.meta.housingPolicy,
});

const { resetState } = antiCrisisMonitoringSlice.actions;
export { resetState };

export default antiCrisisMonitoringSlice.reducer;
