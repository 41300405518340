import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export enum EHttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export type TRequestConfig = {
  method: AxiosRequestConfig['method'],
  url: string,
  params?: AxiosRequestConfig['params'],
  data?: AxiosRequestConfig['data'],
  config?: AxiosRequestConfig,
  abort?: boolean,
};

type TInterceptorRejectCallback = (error: AxiosError) => Promise<any>;

export type TInterceptorRequestResolveCallback = (config: AxiosRequestConfig) => AxiosRequestConfig;

export type TInterceptorRequestRejectCallback = TInterceptorRejectCallback;

export type TInterceptorResponseResolveCallback = (response: AxiosResponse) => AxiosResponse;

export type TInterceptorResponseRejectCallback = TInterceptorRejectCallback;

export type TInterceptor = number;

export interface IHttp {
  get: <T>(
    url: string,
    query?: AxiosRequestConfig['params'],
    options?: { config?: AxiosRequestConfig, abort?: boolean }
  ) => Promise<AxiosResponse<T>>;
  post: <T>(
    url: string,
    data?: AxiosRequestConfig['data'],
    options?: { config?: AxiosRequestConfig, abort?: boolean }
  ) => Promise<AxiosResponse<T>>;
  put: <T>(
    url: string,
    data?: AxiosRequestConfig['data'],
    options?: { config?: AxiosRequestConfig, abort?: boolean }
  ) => Promise<AxiosResponse<T>>;
  delete: <T>(
    url: string,
    options: { config?: AxiosRequestConfig, abort?: boolean },
  ) => Promise<AxiosResponse<T>>;
  setRequestInterceptors: (
    resolveCallback: TInterceptorRequestResolveCallback,
    rejectCallback?: TInterceptorRequestRejectCallback
  ) => TInterceptor;
  ejectRequestInterceptor: (interceptor: TInterceptor) => void;
  setResponseInterceptors: (
    resolveCallback: TInterceptorResponseResolveCallback,
    rejectCallback?: TInterceptorResponseRejectCallback,
  ) => TInterceptor;
  ejectResponseInterceptor: (interceptor: TInterceptor) => void;
}
