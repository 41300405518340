import { combineReducers } from '@reduxjs/toolkit';

import { EEntities } from '@core/store/constants';

import entityReducer from '@features/entity/store';
import infraMenuWidgets from '@features/landing/store/infraMenuWidgets';
import landingHousingCommissioning from '@features/landing/store/landingHousingCommissioning';
import lightsReducer from '@features/map/store/lights';
import monitoringReducer from '@features/strategy/store/monitoring';
import detailingSvetoforReducer from '@features/svetofors/DetailingSvetofor/store/detailingSvetofor';

const reducers = combineReducers({
  [EEntities.ENTITY]: entityReducer,
  [EEntities.MONITORING]: monitoringReducer,
  [EEntities.DETAILING_SVETOFOR]: detailingSvetoforReducer,
  [EEntities.LANDING_HOUSING_COMMISSIONING]: landingHousingCommissioning,
  [EEntities.INFRASTRUCTURE_MENU_WIDGETS_PUBLIC]: infraMenuWidgets,
  [EEntities.LIGHTS]: lightsReducer,
});

export default reducers;
