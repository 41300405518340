import { IGenericState } from '@core/store/types';

export const initialMeta = {
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: false,
};

export const createGenericState = <T>(data?: T): IGenericState<T> => ({
  data: data || null,
  meta: initialMeta,
});
