import { TPollVisibility } from '@core/models/poll';

import { IPollFormValues, IQuestionField, IVisibilityField } from '../components/PollForm/types';

import { IPollPayload } from './types';

export const getPollVisibility = ({
  isPrivate,
  isPublic,
}: IVisibilityField): TPollVisibility => {
  if (isPrivate && isPublic) {
    return 'both';
  }

  return isPrivate ? 'authorized' : 'anonymous';
};

export const assignWeights = (
  questions: IQuestionField[],
) => questions.map(
  (question, questionIndex) => ({
    ...question,
    weight: questionIndex,
    variants: question.variants.map(
      (variant, variantIndex) => ({
        ...variant,
        weight: variantIndex,
      }),
    ),
  }),
);

export const preparePollPayload = (formValues: IPollFormValues): IPollPayload => ({
  ...formValues,
  visibility: getPollVisibility(formValues.visibility),
});
