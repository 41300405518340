import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authorize, parseUser } from '@core/services/Auth';
import { IGenericMetaState, TRootState } from '@core/store/types';
import { getFulfilledMeta, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

import { apiFetchSignIn, apiRefreshJWT } from '@features/authentication/api';
import {
  IAuthenticationApiModel,
  IRefreshJWTPayload,
  ISignInPayloadApiModel,
  IUser,
} from '@features/authentication/models';

interface IJWTTokens {
  access: string | null;
  refresh: string | null;
}

interface IAuthenticationDataState {
  user: IUser | null;
  isAuthChecked: boolean;
  tokens: IJWTTokens,
}

interface IAuthenticationState {
  meta: IGenericMetaState,
  data: IAuthenticationDataState,
  refreshMeta: IGenericMetaState,
}

export const fetchSignIn = createAsyncThunk<IAuthenticationApiModel, ISignInPayloadApiModel>(
  'authentication/signIn',
  async (arg, { rejectWithValue }) => {
    try {
      const { login, password } = arg;
      const { access, refresh } = await apiFetchSignIn({ login, password });
      authorize(access, refresh);
      const user = parseUser(access);
      return { user, isAuthChecked: true };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const refreshJWT = createAsyncThunk<IAuthenticationApiModel, IRefreshJWTPayload>(
  'authentication/refresh',
  async ({ token }, { rejectWithValue }) => {
    try {
      const { access } = await apiRefreshJWT({ token });
      authorize(access, token);
      const user = parseUser(access);
      return { user };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IAuthenticationState = {
  meta: {
    fetchLoading: false,
    fetchSuccess: false,
    fetchError: false,
  },
  data: {
    user: null,
    isAuthChecked: false,
    tokens: {
      access: null,
      refresh: null,
    },
  },
  refreshMeta: {
    fetchLoading: false,
    fetchSuccess: false,
    fetchError: false,
  },
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser | null>) {
      state.data.user = action.payload;
    },
    setIsAuthChecked(state, action: PayloadAction<boolean>) {
      state.data.isAuthChecked = action.payload;
    },
    setJWTTokens(state, action: PayloadAction<IJWTTokens>) {
      state.data.tokens = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSignIn.pending, setPendingState);
    builder.addCase(fetchSignIn.fulfilled, (state, action) => {
      state.data.user = action.payload.user;
      state.data.isAuthChecked = action.payload.isAuthChecked || false;
      state.meta = getFulfilledMeta();
    });
    builder.addCase(fetchSignIn.rejected, setRejectedState);

    builder.addCase(refreshJWT.pending, (state) => {
      state.refreshMeta.fetchLoading = true;
      state.refreshMeta.fetchSuccess = false;
      state.refreshMeta.fetchError = false;
    });
    builder.addCase(refreshJWT.fulfilled, (state, action) => {
      state.refreshMeta.fetchLoading = false;
      state.refreshMeta.fetchSuccess = true;
      state.refreshMeta.fetchError = false;
      state.data.user = action.payload.user;
      state.data.isAuthChecked = true;
    });
    builder.addCase(refreshJWT.rejected, (state) => {
      state.refreshMeta.fetchLoading = false;
      state.refreshMeta.fetchSuccess = false;
      state.refreshMeta.fetchError = true;
      state.data.isAuthChecked = true;
    });
  },
});

type TAuthenticationState = ReturnType<typeof authenticationSlice.reducer>;

export const selectAuthenticationState = ({ authentication }: TRootState): TAuthenticationState => authentication;
export const selectIsAuthenticated = ({ authentication }: TRootState): boolean => Boolean(authentication.data.user);
export const selectIsAuthChecked = ({ authentication }: TRootState): boolean => authentication.data.isAuthChecked;
export const selectUser = ({ authentication } :TRootState): IUser | null => authentication.data.user;
export const selectJWTTokens = ({ authentication } :TRootState): IJWTTokens => authentication.data.tokens;

const { setUser, setJWTTokens, setIsAuthChecked } = authenticationSlice.actions;
export { setUser, setJWTTokens, setIsAuthChecked };

export default authenticationSlice.reducer;
