import { IAnswer, IExtendedPoll, IQuestion } from '@core/models/poll';
import http from '@core/services/Http';

import { IVariantField } from '../components/PollForm/types';

import { IPollPayload, IQuestionPayload } from './types';

export const apiFetchExtendedPolls = () => http.get<IExtendedPoll[]>('/poll?limit=50');

export const apiCreatePoll = (data: IPollPayload) => http.post<IExtendedPoll>('/poll', data);

export const apiUpdatePoll = (
  pollId: number,
  data: IPollPayload,
) => http.put<IExtendedPoll>(`/poll/${pollId}`, data);

export const apiDeletePoll = (pollId: number) => http.delete(`/poll/${pollId}`);

export const apiCreateQuestion = (
  pollId: number,
  data: IQuestionPayload,
) => http.post<IQuestion>(`/poll/question?pollId=${pollId}`, data);

export const apiUpdateQuestion = (
  question: IQuestion,
) => http.put(`/poll/question/${question.id}`, question);

export const apiDeleteQuestion = (
  questionId: number,
) => http.delete(`/poll/question/${questionId}`);

export const apiCreateVariant = (
  questionId: number,
  data: IVariantField,
) => http.post<IAnswer>(`/poll/variant?questionId=${questionId}`, data);

export const apiUpdateVariant = (
  variant: IVariantField,
) => http.put(`/poll/variant/${variant.id}`, variant);

export const apiDeleteVariant = (
  variantId: number,
) => http.delete(`/poll/variant/${variantId}`);
