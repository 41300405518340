import { uniqueId } from 'lodash';

import {
  IBlock,
  IDividerSection,
  ISection,
  ISubBlock,
} from '@features/dynamicTable/components/MobileView/components/Section/types';
import { formatFootnoteNo } from '@features/dynamicTable/helpers/format';
import { IDynamicTableApi, IDynamicTableApiCell, IDynamicTableApiMobileHeader, IDynamicTableApiRow, IDynamicTableCell } from '@features/dynamicTable/types';

import { mapCells } from '../DesktopView/mappers';

const groupByUniqAndOrder = <T>(array: T[], fieldName: keyof T): T[][] => {
  const result: T[][] = [];
  let tmpResult: T[] = [];
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    const nextItem = array[i + 1];
    let hasPushed: boolean = false;

    if (tmpResult.length === 0 || item[fieldName] === nextItem?.[fieldName]) {
      tmpResult.push(item);
      hasPushed = true;
    }

    if (item[fieldName] !== nextItem?.[fieldName] || (i === array.length - 1)) {
      if (!hasPushed) tmpResult.push(item);
      result.push(tmpResult);
      tmpResult = [];
    }
  }

  return result;
};

interface ITempSection {
  id: string;
  title: string | null;
  color: IDynamicTableCell['color'];
  headersWithValue: {
    id: string;
    title: string | null;
    subtitle: string | null;
    caption: string | null;
    secondaryValue: string | null;
    footnoteNo: number | null;
    value: IDynamicTableCell;
  }[],
}

interface ITempDividerSection {
  id: string;
  title: string | null;
  rows: IDynamicTableApiRow[];
}

const excludeHeaderRows = (rows: IDynamicTableApiRow[]): IDynamicTableApiRow[] => rows
  .filter((row) => row.cells.some(({ header }) => !header));

const mapTempDividerSections = (rows: IDynamicTableApiRow[]): ITempDividerSection[] => {
  const tempDividerSections: ITempDividerSection[] = [];
  let tempDividerSection: ITempDividerSection | null = null;
  rows.forEach((row, index) => {
    const isDivider = row.divider;
    const isFirst = index === 0;
    const isLast = index === rows.length - 1;

    if (isDivider) {
      if (tempDividerSection) {
        tempDividerSections.push(tempDividerSection);
      }

      tempDividerSection = {
        id: uniqueId(),
        title: row.cells[0].value,
        rows: [],
      };
    } else {
      if (isFirst) {
        tempDividerSection = {
          id: uniqueId(),
          title: '',
          rows: [],
        };
      }

      if (tempDividerSection) {
        tempDividerSection.rows.push(row);

        if (isLast) {
          tempDividerSections.push(tempDividerSection);
        }
      }
    }
  });

  return tempDividerSections;
};

const mapHeader = (cell: IDynamicTableApiCell, header: IDynamicTableApiMobileHeader, secondaryValue?: string | null) => {
  const { title, subtitle, unit, footnoteNo } = header;

  return {
    id: uniqueId(),
    title: title || null,
    subtitle: subtitle || null,
    caption: unit || null,
    secondaryValue: secondaryValue || null,
    footnoteNo: formatFootnoteNo(footnoteNo),
    value: mapCells([cell])[0],
  };
};

const mapTempSections = (mobileHeaders: IDynamicTableApi['mobileHeaders'], rows: IDynamicTableApiRow[]): ITempSection[] => {
  const tempSections: ITempSection[] = [];

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const isDivider = row.divider;

    if (!isDivider) {
      const tempSection: ITempSection = {
        id: uniqueId(),
        color: row.cells[0].color || null,
        title: `${row.num ? `${row.num}. ` : ''}${row.cells[0].value}`,
        headersWithValue: [],
      };

      for (let j = 1; j < mobileHeaders.length; j++) {
        const header = mobileHeaders[j];
        const { secondaryValue, thirdValue } = header;
        const cell = row.cells[j];

        tempSection.headersWithValue.push(mapHeader({ ...cell, thirdValue: null }, header, secondaryValue));

        if (thirdValue) {
          tempSection.headersWithValue.push(mapHeader(
            { ...cell, secondaryValue: null },
            header,
            thirdValue,
          ));
        }
      }
      tempSections.push(tempSection);
    }
  }

  return tempSections;
};

const mapSections = (tempSections: ITempSection[]): ISection[] => {
  const sections: ISection[] = [];
  tempSections.forEach((tempSection) => {
    const section: ISection = {
      id: uniqueId(),
      title: tempSection.title,
      color: tempSection.color || null,
      blocks: groupByUniqAndOrder(tempSection.headersWithValue, 'title')
        .map((item) => {
          const block: IBlock = {
            id: uniqueId(),
            title: item[0].title,
            subBlocks: groupByUniqAndOrder(item, 'subtitle')
              .map((sb) => {
                const subBlock: ISubBlock = {
                  id: uniqueId(),
                  title: sb[0].subtitle,
                  rows: sb.map(({ caption, secondaryValue, footnoteNo, value }) => (
                    {
                      id: uniqueId(),
                      caption,
                      value,
                      secondaryValue,
                      footnoteNo,
                    }
                  )),
                };
                return subBlock;
              }),
          };

          return block;
        }),
    };

    sections.push(section);
  });

  return sections;
};

export const mapMobileData = ({ mobileHeaders, rows: originRows }: IDynamicTableApi): IDividerSection[] => {
  try {
    const rows = excludeHeaderRows(originRows);
    const tempDividerSections = mapTempDividerSections(rows);
    const dividerSections: IDividerSection[] = [];

    tempDividerSections.forEach((tempDividerSection) => {
      const tempSections = mapTempSections(mobileHeaders, tempDividerSection.rows);
      const sections = mapSections(tempSections);
      dividerSections.push({
        id: tempDividerSection.id,
        title: tempDividerSection.title,
        sections,
      });
    });

    return dividerSections;
  } catch (e) {
    return [];
  }
};
