export enum ENameSpaces {
  COLLECTIONS = 'collections',
  ENTITIES = 'entities',
  AUTHENTICATION = 'authentication',
  DASHBOARDS = 'dashboards',
  FEEDBACK_DICTIONARIES = 'feedbackDictionaries',
  FEEDBACK = 'feedback',
  USER_LOG = 'userLog',
  MATERIALS = 'materials',
  NEWS_ITEM = 'newsItem',
  SVETOFORS = 'svetofors',
  DYNAMIC_TABLE_ADMIN = 'dynamicTableAdmin',
  DISCUSSIONS = 'discussions',
  DISCUSSION = 'discussion',
  DISCUSSION_FILES = 'discussionFiles',
  DISCUSSION_COMMENTS = 'discussionComments',
  MONITORING_UPLOAD = 'monitoringUpload',
  INFRASTRUCTURE_MENU_WIDGETS = 'infrastructureMenuWidgets',
  INFRASTRUCTURE_MENU_UPLOAD = 'infrastructureMenuUpload',
  ANTI_CRISIS_MONITORING = 'antiCrisisMonitoring',
  ANTI_CRISIS_MONITORING_UPLOAD = 'antiCrisisMonitoringUpload',
  NEWS = 'news',
  LEGAL_DOCUMENTS = 'legalDocuments',
  STUDY_MATERIALS = 'studyMaterials',
  PUBLIC_NEWS_ITEM = 'publicNewsItem',
  POLLS = 'polls',
  ALBUMS = 'albums',
  ALBUM_ITEM = 'albumItem',
  MEDIA = 'media',
  ENCYCLOPEDIA = 'encyclopedia',
  MY_DICTIONARY = 'myDictionary',
  EXTENDED_POLLS = 'extendedPolls',
  VIDEO = 'video',
  VIDEO_ARCHIVE = 'videoArchive',
  VIDEO_ITEM = 'videoItem',
  PLACEHOLDERS = 'placeholders',
}

export enum ECollections {
  COLLECTION = 'collection',
  DISTRICTS = 'districts',
  LANDING_TOP_NEWS = 'landingTopNews',
  LANDING_HIGH_NEWS = 'landingHighNews',
  LANDING_NEWS = 'landingNews',
  PINNED_NEWS = 'pinnedNews',
  REGIONS = 'regions',
}

export enum EEntities {
  ENTITY = 'entity',
  MONITORING = 'monitoring',
  DETAILING_SVETOFOR = 'table/light',
  INFRASTRUCTURE_MENU_WIDGETS_PUBLIC = 'infrastructureMenuWidgetsPublic',
  LANDING_HOUSING_COMMISSIONING = 'landingHousingCommissioning',
  LIGHTS = 'lights',
}
