import http from '@core/services/Http';
import { EXTENDED_TIMEOUT } from '@core/services/Http/Http';

export const apiUploadAntiCrisisFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  await http.post('table/anti-crisis', formData, {
    config: {
      timeout: EXTENDED_TIMEOUT,
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  });
};
