import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { getPendingMeta, getRejectedMeta, getFulfilledMeta } from '@core/store/utils/stateSetters';
import { uploadSingleFile } from '@core/utils/uploadFile';

import { apiUploadAntiCrisisFile } from './api';
import { IAntiCrisisMonitoringUploadState, IUploadMonitoringParams } from './types';

export const fetchUploadMonitoring = createAsyncThunk<PromiseSettledResult<unknown>[], IUploadMonitoringParams>(
  'fetchUploadMonitoring',
  async (params, { rejectWithValue }) => {
    try {
      return await Promise.allSettled([
        params.main && apiUploadAntiCrisisFile(params.main),
        params.weekly && uploadSingleFile({ type: 'anti-crisis-weekly', isPublic: false, file: params.weekly }),
        params.monthly && uploadSingleFile({ type: 'anti-crisis-monthly', isPublic: false, file: params.monthly }),
      ]);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IAntiCrisisMonitoringUploadState = {
  meta: initialMeta,
};

export const antiCrisisMonitoringUploadSlice = createSlice({
  name: 'antiCrisisMonitoringUpload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUploadMonitoring.pending, (state) => {
        state.meta = getPendingMeta();
      })
      .addCase(fetchUploadMonitoring.fulfilled, (state) => {
        state.meta = getFulfilledMeta();
      })
      .addCase(fetchUploadMonitoring.rejected, (state) => {
        state.meta = getRejectedMeta();
      });
  },
});

export const selectMonitoringState = ({ antiCrisisMonitoringUpload }: TRootState) => antiCrisisMonitoringUpload;

export default antiCrisisMonitoringUploadSlice.reducer;
