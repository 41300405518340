import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IEncyclopedia } from '@core/models/encyclopedia';
import { TRootState } from '@core/store/types';
import {
  getFulfilledMeta,
  getPendingMeta,
  getRejectedMeta,
  setGenericFulfilledMetaState,
  setPendingState,
  setRejectedState,
} from '@core/store/utils/stateSetters';

import { apiFetchEncyclopedia, apiFetchPrivateEncyclopedia } from './api';
import { IApiFetchParams } from './api/types';
import { addMyDictionaryItem, deleteMyDictionaryItem } from './components/MyDictionary/store';

interface IFetchEncyclopediaArgs extends IApiFetchParams {
  isPrivate?: boolean;
}

export const fetchEncyclopedia = createAsyncThunk<IEncyclopedia[], IFetchEncyclopediaArgs>(
  'encyclopedia/fetchItems',
  async ({ isPrivate, ...params }, { rejectWithValue }) => {
    try {
      return isPrivate ? await apiFetchPrivateEncyclopedia(params) : await apiFetchEncyclopedia(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEncyclopediaNextPage = createAsyncThunk<IEncyclopedia[], IFetchEncyclopediaArgs>(
  'encyclopedia/fetchNextPage',
  async ({ isPrivate, ...params }, { rejectWithValue }) => {
    try {
      return isPrivate ? await apiFetchPrivateEncyclopedia(params) : await apiFetchEncyclopedia(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

interface IEncyclopediaState {
  data: IEncyclopedia[];
  isAllDataFetched: boolean;
  meta: {
    fetchLoading: boolean;
    fetchSuccess: boolean;
    fetchError: boolean;
  },
  nextPageMeta: {
    fetchLoading: boolean;
    fetchSuccess: boolean;
    fetchError: boolean;
  },
}

const initialState: IEncyclopediaState = {
  data: [],
  isAllDataFetched: false,
  meta: {
    fetchLoading: false,
    fetchSuccess: false,
    fetchError: false,
  },
  nextPageMeta: {
    fetchLoading: false,
    fetchSuccess: false,
    fetchError: false,
  },
};

export const encyclopediaSlice = createSlice({
  name: 'encyclopedia',
  initialState,
  reducers: {
    resetEncyclopedia() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEncyclopedia.pending, setPendingState);
    builder.addCase(fetchEncyclopedia.fulfilled, (state, { payload }) => {
      setGenericFulfilledMetaState(state);

      state.isAllDataFetched = false;
      state.data = payload;
    });
    builder.addCase(fetchEncyclopedia.rejected, setRejectedState);

    builder.addCase(fetchEncyclopediaNextPage.pending, (state) => {
      state.nextPageMeta = getPendingMeta();
    });
    builder.addCase(fetchEncyclopediaNextPage.fulfilled, (state, { payload }) => {
      state.nextPageMeta = getFulfilledMeta();

      if (payload.length < 20) {
        state.isAllDataFetched = true;
      }

      state.data = [...state.data, ...payload];
    });
    builder.addCase(fetchEncyclopediaNextPage.rejected, (state) => {
      state.nextPageMeta = getRejectedMeta();
    });

    // TODO: Не уверен что это лучший вариант отлова добавления и удаления терминов из словаря
    builder.addCase(addMyDictionaryItem.fulfilled, (state, { payload }) => {
      const encyclopediaItem = state.data.find(({ id }) => id === payload);

      if (encyclopediaItem) {
        encyclopediaItem.added = true;
      }
    });

    builder.addCase(deleteMyDictionaryItem.fulfilled, (state, { payload }) => {
      const encyclopediaItem = state.data.find(({ id }) => id === payload);

      if (encyclopediaItem) {
        encyclopediaItem.added = false;
      }
    });
  },
});

const { resetEncyclopedia } = encyclopediaSlice.actions;
export { resetEncyclopedia };

export const selectEncyclopediaState = ({ encyclopedia }: TRootState): IEncyclopediaState => encyclopedia;

export default encyclopediaSlice.reducer;
