import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { isRegionalUser } from '@core/services/Auth';
import {
  apiFetchSvetoforsAggregatedCollection,
  apiFetchSvetoforsCollection,
  apiUploadSvetoforsProjects,
} from '@core/store/modules/svetofors/api';
import {
  IAggregatedSvetoforApi,
  ISvetofor, ISvetoforApi,
  ISvetoforsState,
  IUploadSvetoforsPayloadApiModel,
} from '@core/store/modules/svetofors/types';
import { IGenericMetaState, TRootState } from '@core/store/types';
import { getFulfilledMeta, getPendingMeta, getRejectedMeta, setFulfilledState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

export const uploadSvetoforsData = createAsyncThunk<void, IUploadSvetoforsPayloadApiModel>(
  'svetofors/upload',
  async (payload, { rejectWithValue }) => {
    try {
      const payloadFormData = new FormData();
      payload.files.forEach((file) => {
        payloadFormData.append('files', file);
      });

      await apiUploadSvetoforsProjects(payloadFormData);
    } catch (error) {
      return rejectWithValue('rejected');
    }
  },
);

const mapSvetoforsApi = (svetoforApi: ISvetoforApi): ISvetofor => {
  const { id, number, name, description, tableauUrl, keyInfo, signal, type } = svetoforApi;

  const svetofor: ISvetofor = {
    id,
    number,
    name,
    description,
    tableauUrl: tableauUrl || null,
    type,
    regionalIndicator: {
      key: keyInfo.name,
      value: typeof signal?.keyValue === 'number' ? signal?.keyValue : null,
      light: signal?.light || 0,
    },
  };

  return svetofor;
};

const mapAggregatedSvetoforsApi = (aggregatedSvetoforsApi: IAggregatedSvetoforApi): ISvetofor => {
  const { id, number, name, description, tableauUrl, green, yellow, red, type } = aggregatedSvetoforsApi;
  const svetofor: ISvetofor = {
    id,
    number,
    name,
    description,
    tableauUrl: tableauUrl || null,
    type,
    commonIndicators: {
      green: green || 0,
      yellow: yellow || 0,
      red: red || 0,
    },
  };

  return svetofor;
};

export const fetchSvetoforsCollection = createAsyncThunk<ISvetofor[], void, { state: TRootState }>(
  'svetofors/getCollection',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { authentication }: TRootState = getState();
      const { user } = authentication.data;
      if (!user) throw new Error('User information missing');
      const isRegUser = isRegionalUser(user);

      let mappedData: ISvetofor[];
      if (isRegUser) {
        const data = await apiFetchSvetoforsCollection(user.region.id);
        mappedData = data.map(mapSvetoforsApi);
      } else {
        const data = await apiFetchSvetoforsAggregatedCollection();
        mappedData = data.map(mapAggregatedSvetoforsApi);
      }
      return mappedData;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialMeta = {
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: false,
};

const initialState: ISvetoforsState = {
  data: [],
  meta: initialMeta,
  uploadMeta: initialMeta,
};

export const svetoforsSlice = createSlice({
  name: 'svetoforsUpload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSvetoforsCollection.pending, setPendingState);
    builder.addCase(fetchSvetoforsCollection.fulfilled, setFulfilledState);
    builder.addCase(fetchSvetoforsCollection.rejected, setRejectedState);

    builder.addCase(uploadSvetoforsData.pending, (state) => {
      state.uploadMeta = getPendingMeta();
    });
    builder.addCase(uploadSvetoforsData.fulfilled, (state) => {
      state.uploadMeta = getFulfilledMeta();
    });
    builder.addCase(uploadSvetoforsData.rejected, (state) => {
      state.uploadMeta = getRejectedMeta();
    });
  },
});

export const selectSvetoforsUploadMeta = ({ svetofors }: TRootState): IGenericMetaState => svetofors.uploadMeta;
export const selectSvetoforsState = ({ svetofors }: TRootState): ISvetoforsState => svetofors;

export default svetoforsSlice.reducer;
