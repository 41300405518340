import { createAsyncThunk } from '@reduxjs/toolkit';

import { EMaterialTypes } from '@core/models/media';
import { apiCreateMaterial, apiUpdateMaterial } from '@core/services/media/api';
import { ENameSpaces } from '@core/store/constants';
import { apiFetchDeleteFile } from '@core/store/modules/materials/api';
import { uploadSingleFile } from '@core/utils/uploadFile';

import { IVideoFormValues } from '../components/VideoForm/types';

import { IUpdateVideo } from './types';

const prepareMaterialVideoPayload = (
  formValues: Omit<IVideoFormValues, 'attachments'>,
  fileId: number,
  previewFileId: number,
) => ({
  ...formValues,
  weight: 0,
  type: EMaterialTypes.VIDEO,
  tags: formValues.tags.map((tag) => tag.name),
  fileId,
  previewFileId,
});

const uploadPreviewFile = (file: File) => uploadSingleFile({ file, type: 'video-preview', isPublic: true });
const uploadVideoFile = (file: File) => uploadSingleFile({ file, type: 'media-bank', isPublic: true });

export const createVideo = createAsyncThunk<void, IVideoFormValues>(
  `${ENameSpaces.VIDEO}/create`,
  async ({ attachments, ...formValues }, { rejectWithValue }) => {
    try {
      const [previewFile, videoFile] = attachments as File[];
      const [preview, video] = await Promise.all([uploadPreviewFile(previewFile), uploadVideoFile(videoFile)]);

      await apiCreateMaterial(prepareMaterialVideoPayload(formValues, video.data.id, preview.data.id));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateVideo = createAsyncThunk<void, IUpdateVideo>(
  `${ENameSpaces.VIDEO}/update`,
  async (
    { formValues: { attachments, ...formValues }, video },
    { rejectWithValue },
  ) => {
    try {
      const [previewFile, videoFile] = attachments;

      let previewId = video.previewFile.id;
      let videoId = video.file.id;

      if (previewFile instanceof File) {
        const [{ data }] = await Promise.all([uploadPreviewFile(previewFile), apiFetchDeleteFile(video.previewFile.id)]);
        previewId = data.id;
      }

      if (videoFile instanceof File) {
        const [{ data }] = await Promise.all([uploadVideoFile(videoFile), apiFetchDeleteFile(video.file.id)]);
        videoId = data.id;
      }

      await apiUpdateMaterial(
        video.id,
        prepareMaterialVideoPayload(formValues, videoId, previewId),
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
