import { IFileInfo } from '@core/store/modules/materials/types';

import { IUser } from '@features/authentication/models';

export interface IAlbum {
  id: number;
  name: string;
  description: string;
  createdAt: string;
  materials: IMaterial[];
  tags: ITag[];
}

export interface IMaterialFile extends IFileInfo {
  createdBy: IUser;
}

export interface IMaterial {
  id: number;
  name: string;
  description?: string | null;
  createdAt: string;
  weight: number;
  type: EMaterialTypes;
  file: IMaterialFile;
  previewFile?: IMaterialFile;
  isPublic: boolean;
  date: string;
  tags?: ITag[] | null;
}

export interface IMaterialVideo extends IMaterial {
  previewFile: IMaterialFile;
}

export enum EMaterialTypes {
  PHOTO = 'photo',
  VIDEO = 'video',
  INFOGRAPHICS = 'infographics',
}

export interface ITag {
  id: number;
  name: string;
  createdAt: string;
}

export type TMediaItem = IAlbum | IMaterial;

export enum EMediaItemType {
  PHOTO = 'photo',
  VIDEO = 'video',
  INFOGRAPHICS = 'infographics',
}
