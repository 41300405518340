import { INews } from '@core/models/news';
import { ECollections } from '@core/store/constants';
import { createGenericCollectionSlice } from '@core/store/modules/collections/utils/slice';

const { slice, thunks, selectors } = createGenericCollectionSlice<INews, any>({
  collection: ECollections.PINNED_NEWS,
  thunksConfig: {
    fetchGetCollectionThunk: {
      endpoint: '/news?pinned=true',
      adapter: (response) => ({ items: response }),
    },
  },
});

const { fetchGetCollectionThunk: fetchPinnedNews } = thunks;

const { selectSliceState: selectPinnedNews } = selectors;

export { fetchPinnedNews, selectPinnedNews };

export default slice.reducer;
