import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IExtendedPoll } from '@core/models/poll';
import { ENameSpaces } from '@core/store/constants';
import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { getPendingMeta, getFulfilledMeta, getRejectedMeta } from '@core/store/utils/stateSetters';

import { IPollFormValues } from '../components/PollForm/types';

import { apiCreatePoll, apiDeletePoll, apiFetchExtendedPolls, apiUpdatePoll } from './api';
import { createQuestions, updateQuestions } from './helpers';
import { IExtendedPollsState, IUpdatePollArg } from './types';
import { assignWeights, preparePollPayload } from './utils';

const initialState: IExtendedPollsState = {
  data: [],
  fetchMeta: initialMeta,
  createMeta: initialMeta,
  updateMeta: initialMeta,
  deleteMeta: initialMeta,
};

export const fetchExtendedPolls = createAsyncThunk<IExtendedPoll[]>(
  `${ENameSpaces.EXTENDED_POLLS}/fetch`,
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await apiFetchExtendedPolls();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createPoll = createAsyncThunk<void, IPollFormValues>(
  `${ENameSpaces.EXTENDED_POLLS}/create`,
  async (formValues, { rejectWithValue }) => {
    try {
      const { data: { id } } = await apiCreatePoll(
        preparePollPayload(formValues),
      );

      await createQuestions(id, assignWeights(formValues.questions));
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updatePoll = createAsyncThunk<void, IUpdatePollArg>(
  `${ENameSpaces.EXTENDED_POLLS}/update`,
  async ({ poll, formValues }, { rejectWithValue }) => {
    try {
      await Promise.allSettled([
        apiUpdatePoll(poll.id, preparePollPayload(formValues)),
        updateQuestions(poll, formValues.questions),
      ]);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deletePoll = createAsyncThunk<number, number>(
  `${ENameSpaces.EXTENDED_POLLS}/delete`,
  async (pollId, { rejectWithValue }) => {
    try {
      await apiDeletePoll(pollId);

      return pollId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const extendedPollsSlice = createSlice({
  name: ENameSpaces.EXTENDED_POLLS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExtendedPolls.pending, (state) => {
      state.fetchMeta = getPendingMeta();
    });
    builder.addCase(fetchExtendedPolls.fulfilled, (state, { payload }) => {
      state.fetchMeta = getFulfilledMeta();
      state.data = payload;
    });
    builder.addCase(fetchExtendedPolls.rejected, (state) => {
      state.fetchMeta = getRejectedMeta();
    });

    builder.addCase(createPoll.pending, (state) => {
      state.createMeta = getPendingMeta();
    });
    builder.addCase(createPoll.fulfilled, (state) => {
      state.createMeta = getFulfilledMeta();
    });
    builder.addCase(createPoll.rejected, (state) => {
      state.createMeta = getRejectedMeta();
    });

    builder.addCase(deletePoll.pending, (state) => {
      state.deleteMeta = getPendingMeta();
    });
    builder.addCase(deletePoll.fulfilled, (state, { payload }) => {
      state.deleteMeta = getFulfilledMeta();
      state.data = state.data.filter((poll) => poll.id !== payload);
    });
    builder.addCase(deletePoll.rejected, (state) => {
      state.deleteMeta = getRejectedMeta();
    });

    builder.addCase(updatePoll.pending, (state) => {
      state.updateMeta = getPendingMeta();
    });
    builder.addCase(updatePoll.fulfilled, (state) => {
      state.updateMeta = getFulfilledMeta();
    });
    builder.addCase(updatePoll.rejected, (state) => {
      state.updateMeta = getRejectedMeta();
    });
  },
});

export const selectExtendedPolls = (rootState: TRootState): IExtendedPollsState => rootState[ENameSpaces.EXTENDED_POLLS];

export default extendedPollsSlice.reducer;
