import { EEntities } from '@core/store/constants';
import { createGenericEntitySlice } from '@core/store/modules/entities/utils/slice';

import { IMonitoring } from '../components/Monitoring/types';

const { slice, thunks, selectors } = createGenericEntitySlice<IMonitoring, any>({
  entity: EEntities.MONITORING,
});

const {
  fetchGetEntityThunk: fetchMonitoring,
} = thunks;

const {
  selectSliceState: selectMonitoringState,
} = selectors;

export {
  fetchMonitoring,
  selectMonitoringState,
};

export default slice.reducer;
