import { IAlbum, TMediaItem } from '@core/models/media';
import http from '@core/services/Http';

import { IMaterialPayload, IAlbumPayload, IFetchMediaItemsArg, IMediaFilters } from './types';

export const apiFetchMedia = (
  payload: IMediaFilters | Partial<IFetchMediaItemsArg>,
) => http.get<TMediaItem[]>('/media-bank/mixed', payload);

export const apiCreateMaterial = (
  payload: IMaterialPayload,
) => http.post('media-bank/material', payload);

export const apiUpdateMaterial = (
  materialId: number,
  payload: IMaterialPayload,
) => http.put(`media-bank/material/${materialId}`, payload);

export const apiDeleteMaterial = (
  materialId: number,
) => http.delete(`media-bank/material/${materialId}`);

export const apiCreateAlbum = (
  payload: IAlbumPayload,
) => http.post<IAlbum>('media-bank/album', payload);

export const apiUpdateAlbum = (
  albumId: number,
  payload: IAlbumPayload,
) => http.put(`media-bank/album/${albumId}`, payload);

export const apiDeleteAlbum = (
  albumId: number,
) => http.delete(`media-bank/album/${albumId}`);
