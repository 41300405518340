import { EMaterialTypes, IMaterialFile } from '@core/models/media';
import { uploadSingleFile } from '@core/utils/uploadFile';

import { TAttachment } from '@features/adminPanel/components/Media/components/AttachMedia/types';
import { isMaterialFile } from '@features/adminPanel/components/Media/utils';

import { apiCreateMaterial } from './api';
import { INewFile } from './types';

export const splitAttachments = (
  attachments: TAttachment[],
) => attachments.reduce((acc, attachment, index) => {
  if (attachment instanceof File) {
    acc.newFiles.push({ weight: index, file: attachment });
  } else if (isMaterialFile(attachment)) {
    acc.oldFiles.push(attachment);
  }

  return acc;
}, {
  newFiles: [],
  oldFiles: [],
} as { newFiles: INewFile[], oldFiles: IMaterialFile[] });

export const createMaterial = async (
  { file, weight }: INewFile,
  albumId?: number,
) => {
  const { data: uploadedFile } = await uploadSingleFile({
    file,
    type: 'media-bank',
    isPublic: true,
  });

  await apiCreateMaterial({
    name: uploadedFile.name,
    description: '',
    weight,
    type: EMaterialTypes.PHOTO,
    fileId: uploadedFile.id,
    albumId,
    tags: [],
  });
};
