import { EEntities } from '@core/store/constants';
import { createGenericEntitySlice } from '@core/store/modules/entities/utils/slice';

import { IWidgetsModel } from '@features/infrastructureMenu/store/model';

const { slice, thunks, selectors } = createGenericEntitySlice<IWidgetsModel, any>({
  entity: EEntities.INFRASTRUCTURE_MENU_WIDGETS_PUBLIC,
  thunksConfig: {
    fetchGetEntityThunk: {
      endpoint: 'infrastructure-menu-public/widgets',
    },
  },
});

const {
  fetchGetEntityThunk: fetchInfraMenuWidgets,
} = thunks;

const {
  selectSliceState: selectInfraMenuWidgets,
} = selectors;

export {
  fetchInfraMenuWidgets,
  selectInfraMenuWidgets,
};

export default slice.reducer;
