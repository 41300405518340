import { EMaterialTypes, IMaterial, IMaterialFile, IMaterialVideo, TMediaItem } from '@core/models/media';
import http from '@core/services/Http';
import { createDownloadLink, getFileUrl, getPublicFileUrl, parseFilename } from '@core/utils/downloadFile';

export function isMaterial(mediaItem: any): mediaItem is IMaterial {
  return 'file' in mediaItem && 'type' in mediaItem;
}

export const isMaterialVideo = (material: IMaterial): material is IMaterialVideo => material.type === EMaterialTypes.VIDEO;

export const isMaterialFile = (item: any): item is IMaterialFile => 'createdBy' in item && 'size' in item;

export function getMediaItemType(mediaItem: TMediaItem) {
  if (isMaterial(mediaItem)) {
    return mediaItem.type === 'video' ? 'Видео' : 'Инфографика';
  }

  return 'Альбом';
}

export function isSameMediaItem(itemA: TMediaItem, itemB: TMediaItem) {
  // @ts-ignore
  return itemA.type === itemB.type && itemA.id === itemB.id;
}

export async function downloadFile(file: IMaterialFile) {
  const { data, headers } = await http.get<Blob>(
    file.isPublic ? getPublicFileUrl(file.id, false) : getFileUrl(file.id, false),
    {},
    { config: { responseType: 'blob' } },
  );

  createDownloadLink(
    new Blob([data]),
    parseFilename(headers['content-disposition']),
  );
}
