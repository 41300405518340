import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IGenericMetaState, TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import {
  setFulfilledMetaState,
  setPendingState,
  setRejectedState,
} from '@core/store/utils/stateSetters';

import { apiUploadInfrastructureMenu } from './api';
import {
  IUploadInfrastructureMenuPayload,
  IUploadInfrastructureMenuState,
} from './types';

export const uploadInfrastructureMenuData = createAsyncThunk<void, IUploadInfrastructureMenuPayload>(
  'InfrastructureMenuUpload/upload',
  async (payload, { rejectWithValue }) => {
    try {
      const payloadFormData = new FormData();
      payloadFormData.append('file', payload.file);
      await apiUploadInfrastructureMenu(payloadFormData);
    } catch (error) {
      return rejectWithValue('rejected');
    }
  },
);

const initialState: IUploadInfrastructureMenuState = {
  meta: initialMeta,
};

export const InfrastructureMenuUploadSlice = createSlice({
  name: 'InfrastructureMenuUpload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadInfrastructureMenuData.pending, setPendingState);
    builder.addCase(uploadInfrastructureMenuData.fulfilled, setFulfilledMetaState);
    builder.addCase(uploadInfrastructureMenuData.rejected, setRejectedState);
  },
});

export const selectInfrastructureMenuUploadMeta = ({ infrastructureMenuUpload }: TRootState): IGenericMetaState => infrastructureMenuUpload.meta;
export const selectInfrastructureMenuState = ({ infrastructureMenuUpload }: TRootState): IUploadInfrastructureMenuState => infrastructureMenuUpload;

export default InfrastructureMenuUploadSlice.reducer;
