import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig, CancelTokenSource, AxiosError } from 'axios';

import { isBrowser } from '@core/utils/isBrowser';

import {
  EHttpMethod,
  TRequestConfig,
  TInterceptorRequestResolveCallback,
  TInterceptorRequestRejectCallback,
  TInterceptorResponseResolveCallback,
  TInterceptorResponseRejectCallback,
  TInterceptor,
  IHttp,
} from './types';

export const DEFAULT_TIMEOUT = 30000;
export const EXTENDED_TIMEOUT = 90000;
export const ClientTypeHeader = isBrowser() ? btoa(window?.location?.host) : null;

export default class Http implements IHttp {
  private readonly http: AxiosInstance;

  // Текущий набор исполняемых запросов
  private readonly requests: Record<string, CancelTokenSource>;

  constructor(baseURL: string = '/', headers?: AxiosRequestConfig['headers'], paramsSerializer?: AxiosRequestConfig['paramsSerializer']) {
    this.http = axios.create({
      baseURL,
      paramsSerializer,
      timeout: DEFAULT_TIMEOUT,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...ClientTypeHeader && { 'Client-Type': ClientTypeHeader },
        ...headers,
      },
    });
    this.requests = {};
  }

  private async request<T>(requestConfig: TRequestConfig): Promise<AxiosResponse<T>> {
    const {
      method,
      url,
      params,
      data,
      config = {},
      abort = false,
    } = requestConfig;

    if (abort) {
      this.abortRequest(url);
    }

    const request: AxiosRequestConfig = {
      method,
      url,
      ...config,
      ...params && { params },
      ...data && { data },
      ...abort && { cancelToken: this.createAbortController(url).token },
    };

    const response = await this.http.request<T>(request);

    this.deleteRequest(url);

    return response;
  }

  private createAbortController(key: string): CancelTokenSource {
    const controller = axios.CancelToken.source();
    this.requests[key] = controller;

    return controller;
  }

  private abortRequest(key: string): void {
    const controller = this.requests[key];

    if (!controller) {
      return;
    }

    controller.cancel();
  }

  private deleteRequest(key: string): void {
    delete this.requests[key];
  }

  public get<T>(
    url: string,
    params?: AxiosRequestConfig['params'],
    options: { config?: AxiosRequestConfig, abort?: boolean } = {},
  ): Promise<AxiosResponse<T>> {
    const { config, abort } = options;

    return this.request<T>({
      method: EHttpMethod.GET,
      url,
      params,
      config,
      abort,
    });
  }

  public post<T>(
    url: string,
    data: AxiosRequestConfig['data'],
    options: { config?: AxiosRequestConfig, abort?: boolean } = {},
  ): Promise<AxiosResponse<T>> {
    const { config, abort } = options;

    return this.request<T>({
      method: EHttpMethod.POST,
      url,
      data,
      config,
      abort,
    });
  }

  public put<T>(
    url: string,
    data: AxiosRequestConfig['data'],
    options: { config?: AxiosRequestConfig, abort?: boolean } = {},
  ): Promise<AxiosResponse<T>> {
    const { config, abort } = options;

    return this.request<T>({
      method: EHttpMethod.PUT,
      url,
      data,
      config,
      abort,
    });
  }

  public patch<T>(
    url: string,
    data: AxiosRequestConfig['data'],
    options: { config?: AxiosRequestConfig, abort?: boolean } = {},
  ): Promise<AxiosResponse<T>> {
    const { config, abort } = options;

    return this.request<T>({
      method: EHttpMethod.PATCH,
      url,
      data,
      config,
      abort,
    });
  }

  public delete<T>(
    url: string,
    data?: AxiosRequestConfig['data'],
    options: { config?: AxiosRequestConfig, abort?: boolean } = {},
  ): Promise<AxiosResponse<T>> {
    const { config, abort } = options;

    return this.request<T>({
      method: EHttpMethod.DELETE,
      data,
      url,
      config,
      abort,
    });
  }

  public retryRequestFromError(error: AxiosError): Promise<any> {
    const { method, url = '', ...config } = error.config;
    return this.request({
      method,
      url,
      config,
    });
  }

  public setRequestInterceptors(
    resolveCallback: TInterceptorRequestResolveCallback,
    rejectCallback?: TInterceptorRequestRejectCallback,
  ): TInterceptor {
    return this.http.interceptors.request.use(resolveCallback, rejectCallback);
  }

  public ejectRequestInterceptor(interceptor: TInterceptor): void {
    this.http.interceptors.request.eject(interceptor);
  }

  public setResponseInterceptors(
    resolveCallback: TInterceptorResponseResolveCallback,
    rejectCallback?: TInterceptorResponseRejectCallback,
  ): TInterceptor {
    return this.http.interceptors.response.use(resolveCallback, rejectCallback);
  }

  public ejectResponseInterceptor(interceptor: TInterceptor): void {
    this.http.interceptors.response.eject(interceptor);
  }
}
