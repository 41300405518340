import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IGenericMetaState, TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import {
  setFulfilledMetaState,
  setPendingState,
  setRejectedState,
} from '@core/store/utils/stateSetters';

import { apiUploadMonitoring } from './api';
import {
  IUploadMonitoringPayload,
  IUploadMonitoringState,
} from './types';

export const uploadMonitoringData = createAsyncThunk<void, IUploadMonitoringPayload>(
  'monitoringUpload/upload',
  async (payload, { rejectWithValue }) => {
    try {
      const payloadFormData = new FormData();
      payloadFormData.append('file', payload.file);
      await apiUploadMonitoring(payloadFormData);
    } catch (error) {
      return rejectWithValue('rejected');
    }
  },
);

const initialState: IUploadMonitoringState = {
  meta: initialMeta,
};

export const monitoringUploadSlice = createSlice({
  name: 'monitoringUpload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadMonitoringData.pending, setPendingState);
    builder.addCase(uploadMonitoringData.fulfilled, setFulfilledMetaState);
    builder.addCase(uploadMonitoringData.rejected, setRejectedState);
  },
});

export const selectMonitoringUploadMeta = ({ monitoringUpload }: TRootState): IGenericMetaState => monitoringUpload.meta;
export const selectMonitoringState = ({ monitoringUpload }: TRootState): IUploadMonitoringState => monitoringUpload;

export default monitoringUploadSlice.reducer;
