import { IGenericMetaState, IGenericState } from '@core/store/types';

export const getPendingMeta = (): IGenericMetaState => ({
  fetchLoading: true,
  fetchSuccess: false,
  fetchError: false,
});

export const getFulfilledMeta = (): IGenericMetaState => ({
  fetchLoading: false,
  fetchSuccess: true,
  fetchError: false,
});

export const getRejectedMeta = (): IGenericMetaState => ({
  fetchLoading: false,
  fetchSuccess: false,
  fetchError: true,
});

export const setGenericPendingState = <T>(state: IGenericState<T>): IGenericState<T> => {
  state.meta = getPendingMeta();

  return state;
};

export const setPendingState = <T extends { meta: IGenericMetaState }>(state: T): T => {
  state.meta = getPendingMeta();

  return state;
};

export const setGenericFulfilledMetaState = <T>(state: IGenericState<T>): IGenericState<T> => {
  state.meta = getFulfilledMeta();

  return state;
};

export const setFulfilledMetaState = <T extends { meta: IGenericMetaState }>(state: T): T => {
  state.meta = getFulfilledMeta();

  return state;
};

export const setGenericFulfilledState = <T>(state: IGenericState<T>, { payload }: { payload: any }): IGenericState<T> => {
  state.data = payload;
  setGenericFulfilledMetaState(state);

  return state;
};

export const setFulfilledState = <T extends { meta: IGenericMetaState, data: any }>(state: T, { payload }: { payload: any }): T => {
  state.data = payload;
  setGenericFulfilledMetaState(state);

  return state;
};

export const setGenericRejectedState = <T>(state: IGenericState<T>): IGenericState<T> => {
  state.meta = getRejectedMeta();

  return state;
};

export const setRejectedState = <T extends { meta: IGenericMetaState }>(state: T): T => {
  state.meta = getRejectedMeta();

  return state;
};
