import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { IGenericMetaState, TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import {
  getFulfilledMeta,
  getPendingMeta,
  getRejectedMeta,
  setFulfilledMetaState,
  setPendingState,
  setRejectedState,
} from '@core/store/utils/stateSetters';

import {
  apiCreateComment,
  apiDeleteComment,
  apiFetchComments,
} from '@features/materials/components/Discussion/components/Comments/api';
import {
  ECommentSorting,
  IComment,
  ICommentEditPayload,
  ICommentsState,
} from '@features/materials/components/Discussion/components/Comments/model';

export const fetchComments = createAsyncThunk<{ data: ICommentsState['data'], params: ICommentsState['params'] }>(
  'discussionComment/fetchAll',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { params, data } = (getState() as TRootState).discussionComments;
      const newData = await apiFetchComments(params);
      const newParams = cloneDeep(params);
      newParams.pagination.isLastPage = newData.length === 0 || newData.length < params.pagination.limit;

      return {
        data: [...data, ...newData],
        params: newParams,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createComment = createAsyncThunk<void, ICommentEditPayload>(
  'discussionComment/create',
  async (payload, { rejectWithValue }) => {
    try {
      const payloadFormData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        payloadFormData.append(key, value);
      });

      await apiCreateComment(payloadFormData);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteComment = createAsyncThunk<void, IComment['id']>(
  'discussionComment/delete',
  async (commentId, { rejectWithValue }) => {
    try {
      await apiDeleteComment(commentId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: ICommentsState = {
  data: [],
  params: {
    discussionId: null,
    sorting: ECommentSorting.NEW,
    pagination: {
      limit: 10,
      offset: 0,
      isLastPage: false,
    },
  },
  meta: initialMeta,
  editMeta: initialMeta,
  deleteMeta: initialMeta,
};

export const discussionCommentsSlice = createSlice({
  name: 'discussionComments',
  initialState,
  reducers: {
    setParams(state, action: PayloadAction<Partial<ICommentsState['params']>>) {
      state.params = { ...state.params, ...action.payload };
    },
    resetState: () => initialState,
    resetEditMeta(state) {
      state.editMeta = initialMeta;
    },
    resetDeleteMeta(state) {
      state.deleteMeta = initialMeta;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchComments.pending, setPendingState);
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      setFulfilledMetaState(state);
      state.data = action.payload.data;
      state.params = action.payload.params;
    });
    builder.addCase(fetchComments.rejected, setRejectedState);

    builder.addCase(createComment.pending, (state) => {
      state.editMeta = getPendingMeta();
    });
    builder.addCase(createComment.fulfilled, (state) => {
      state.editMeta = getFulfilledMeta();
    });
    builder.addCase(createComment.rejected, (state) => {
      state.editMeta = getRejectedMeta();
    });

    builder.addCase(deleteComment.pending, (state) => {
      state.deleteMeta = getPendingMeta();
    });
    builder.addCase(deleteComment.fulfilled, (state) => {
      state.deleteMeta = getFulfilledMeta();
    });
    builder.addCase(deleteComment.rejected, (state) => {
      state.deleteMeta = getRejectedMeta();
    });
  },
});

const { setParams, resetState, resetEditMeta, resetDeleteMeta } = discussionCommentsSlice.actions;
export { setParams, resetState, resetEditMeta, resetDeleteMeta };

export const selectCommentsState = ({ discussionComments }: TRootState): ICommentsState => discussionComments;
export const selectParams = ({ discussionComments }: TRootState): ICommentsState['params'] => discussionComments.params;
export const selectCommentEditMeta = ({ discussionComments }: TRootState): IGenericMetaState => discussionComments.editMeta;
export const selectCommentDeleteMeta = ({ discussionComments }: TRootState): IGenericMetaState => discussionComments.deleteMeta;

export const setParamsAndFetchComments = createAsyncThunk<void, Partial<ICommentsState['params']>>(
  'discussionComment/setParamsAndFetchAll',
  (params, { dispatch }) => {
    dispatch(setParams(params));
    dispatch(fetchComments());
  },
);

export const setSorting = createAsyncThunk<void, ECommentSorting>(
  'discussionComment/setSortingAndFetchAll',
  (sorting, { dispatch, getState }) => {
    const { discussionId } = (getState() as TRootState).discussionComments.params;

    dispatch(resetState());
    dispatch(setParams({ discussionId, sorting }));
    dispatch(fetchComments());
  },
);

export const fetchNextPage = createAsyncThunk<void>(
  'discussionComment/fetchNextPage',
  (arg, { dispatch, getState }) => {
    const { isLastPage, offset, limit } = (getState() as TRootState).discussionComments.params.pagination;
    const pagination = {
      isLastPage,
      limit,
      offset: offset + limit,
    };
    dispatch(setParams({ pagination }));
    dispatch(fetchComments());
  },
);

export default discussionCommentsSlice.reducer;
