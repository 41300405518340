import { INews } from '@core/models/news';
import { ECollections } from '@core/store/constants';
import { createGenericCollectionSlice } from '@core/store/modules/collections/utils/slice';

import { DEFAULT_LIMIT } from '../components/DefaultNews/constants';

const { slice, thunks, selectors } = createGenericCollectionSlice<INews, any>({
  collection: ECollections.LANDING_NEWS,
  thunksConfig: {
    fetchGetCollectionThunk: {
      endpoint: '/news-public',
      adapter: (response) => ({ items: response }),
    },
    fetchGetNextPageCollectionThunk: {
      endpoint: '/news-public',
      adapter: (response) => ({ items: response, pageLimit: DEFAULT_LIMIT }),
    },
  },
});

const { fetchGetCollectionThunk: fetchLandingNews, fetchGetNextPageCollectionThunk: fetchLandingNewsNextPage } = thunks;

const { selectSliceState: selectLandingNews } = selectors;

export { fetchLandingNews, fetchLandingNewsNextPage, selectLandingNews };

export default slice.reducer;
