import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGenericMetaState, TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import {
  getFulfilledMeta,
  getPendingMeta,
  getRejectedMeta,
  setGenericFulfilledMetaState,
  setPendingState,
  setRejectedState,
} from '@core/store/utils/stateSetters';
import { uploadSingleFile } from '@core/utils/uploadFile';

import {
  apiFetchDeleteFile,
  apiFetchFilesList,
  apiFetchUpdateFile,
  apiUploadMaterialFiles,
} from './api';
import {
  EFileType,
  IFetchCreateFileParams,
  IFetchMaterialsFilesParams,
  IFetchUpdateFileParams,
  IFileInfo,
  IFileListApiParams,
  IMaterialsFilesData,
  IMaterialsState,
  IUploadMaterialsFilesApiModel,
} from './types';

export const fetchMaterialsFiles = createAsyncThunk<IFileInfo[], IFetchMaterialsFilesParams>(
  'materials/files',
  async ({ type }, { getState, rejectWithValue }) => {
    try {
      const { params } = (getState() as TRootState).materials.data[type];
      const apiParams: IFileListApiParams = { type };

      if (params.sort) {
        apiParams.sortingField = params.sort.key as keyof IFileInfo;
        apiParams.sortingDirection = params.sort.direction;
      }

      return await apiFetchFilesList(apiParams);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const uploadMaterialFiles = createAsyncThunk<void, IUploadMaterialsFilesApiModel>(
  'materials/uploadFiles',
  async (payload, { rejectWithValue }) => {
    try {
      const payloadFormData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (key === 'files') {
          value.forEach((file: File) => {
            payloadFormData.append(key, file);
          });
        } else {
          payloadFormData.append(key, value);
        }
      });

      await apiUploadMaterialFiles(payloadFormData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchCreateFile = createAsyncThunk<void, IFetchCreateFileParams>(
  'materials/create',
  async (params, { rejectWithValue }) => {
    try {
      await uploadSingleFile(params);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchUpdateFile = createAsyncThunk<void, IFetchUpdateFileParams>(
  'materials/update',
  async (params, { rejectWithValue }) => {
    try {
      await apiFetchUpdateFile(params);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteMaterialsFile = createAsyncThunk<void, number>(
  'materials/delete',
  async (id, { rejectWithValue }) => {
    try {
      await apiFetchDeleteFile(id);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const initialData: IMaterialsFilesData = {
  data: [],
  params: {
    sort: {},
  },
};

const initialState: IMaterialsState = {
  data: {
    [EFileType.HEADQUARTERS]: initialData,
    [EFileType.COMMON]: initialData,
    [EFileType.STUDY]: initialData,
    [EFileType.NEWS]: initialData,
    [EFileType.DECREE]: initialData,
    [EFileType.FEDERAL_LAW]: initialData,
  },
  meta: initialMeta,
  editMeta: initialMeta,
  uploadMeta: initialMeta,
  deleteMeta: initialMeta,
};

export const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    resetEditMeta(state) {
      state.editMeta = initialMeta;
    },
    resetUploadMeta(state) {
      state.uploadMeta = initialMeta;
    },
    resetDeleteMeta(state) {
      state.deleteMeta = initialMeta;
    },
    setParams(state, action: PayloadAction<{ type: EFileType, params: Partial<IMaterialsFilesData['params']> }>) {
      const { type, params } = action.payload;
      state.data[type].params = { ...state.data[type].params, ...params };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMaterialsFiles.pending, setPendingState);
    builder.addCase(fetchMaterialsFiles.fulfilled, (state, action) => {
      setGenericFulfilledMetaState(state);

      const fileType = action.meta.arg.type;
      state.data = {
        ...state.data,
        [fileType]: {
          params: state.data[fileType].params,
          data: action.payload,
        },
      };
    });
    builder.addCase(fetchMaterialsFiles.rejected, setRejectedState);

    builder.addCase(uploadMaterialFiles.pending, (state) => {
      state.uploadMeta = getPendingMeta();
    });
    builder.addCase(uploadMaterialFiles.fulfilled, (state) => {
      state.uploadMeta = getFulfilledMeta();
    });
    builder.addCase(uploadMaterialFiles.rejected, (state) => {
      state.uploadMeta = getRejectedMeta();
    });

    builder.addCase(fetchCreateFile.pending, (state) => {
      state.editMeta = getPendingMeta();
    });
    builder.addCase(fetchCreateFile.fulfilled, (state) => {
      state.editMeta = getFulfilledMeta();
    });
    builder.addCase(fetchCreateFile.rejected, (state) => {
      state.editMeta = getRejectedMeta();
    });

    builder.addCase(fetchUpdateFile.pending, (state) => {
      state.editMeta = getPendingMeta();
    });
    builder.addCase(fetchUpdateFile.fulfilled, (state) => {
      state.editMeta = getFulfilledMeta();
    });
    builder.addCase(fetchUpdateFile.rejected, (state) => {
      state.editMeta = getRejectedMeta();
    });

    builder.addCase(deleteMaterialsFile.pending, (state) => {
      state.deleteMeta = getPendingMeta();
    });
    builder.addCase(deleteMaterialsFile.fulfilled, (state) => {
      state.deleteMeta = getFulfilledMeta();
    });
    builder.addCase(deleteMaterialsFile.rejected, (state) => {
      state.deleteMeta = getRejectedMeta();
    });
  },
});

const { resetEditMeta, resetUploadMeta, resetDeleteMeta, setParams } = materialsSlice.actions;
export { resetEditMeta, resetUploadMeta, resetDeleteMeta, setParams };

export const selectMaterials = ({ materials }: TRootState): IMaterialsState => materials;
export const selectMaterialsFilesEditMeta = ({ materials }: TRootState): IGenericMetaState => materials.editMeta;
export const selectMaterialsFilesUploadMeta = ({ materials }: TRootState): IGenericMetaState => materials.uploadMeta;
export const selectMaterialsFilesDeleteMeta = ({ materials }: TRootState): IGenericMetaState => materials.deleteMeta;

export default materialsSlice.reducer;
