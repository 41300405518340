import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { setFulfilledState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

import { apiFetchWidgets } from './api';
import { IWidgetsModel } from './model';
import { IWidgetsState } from './types';

export const fetchWidgets = createAsyncThunk<IWidgetsModel, IWidgetsState['filters']>(
  'infrastructureMenuWidgets/fetchWidgets',
  async (params, { rejectWithValue }) => {
    try {
      return await apiFetchWidgets(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IWidgetsState = {
  meta: initialMeta,
  data: null,
  filters: {
    district: null,
    region: null,
  },
};

export const widgetsSlice = createSlice({
  name: 'infrastructureMenuWidgets',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<IWidgetsState['filters']>) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWidgets.pending, setPendingState);
    builder.addCase(fetchWidgets.fulfilled, setFulfilledState);
    builder.addCase(fetchWidgets.rejected, setRejectedState);
  },
});

const { setFilters } = widgetsSlice.actions;
export { setFilters };

export const selectWidgetsState = ({ infrastructureMenuWidgets }:TRootState): IWidgetsState => infrastructureMenuWidgets;

export default widgetsSlice.reducer;
