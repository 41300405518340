import { EMaterialTypes, IMaterialVideo } from '@core/models/media';
import http from '@core/services/Http';

import { IFetchVideoArchiveParams } from './types';

export const apiFetchVideoArchive = async (params: IFetchVideoArchiveParams) => {
  const { data } = await http.get<IMaterialVideo[]>(`/media-bank/material?type=${EMaterialTypes.VIDEO}`, params);
  return data;
};

export const apiFetchPublicVideoArchive = async (params: IFetchVideoArchiveParams) => {
  const { data } = await http.get<IMaterialVideo[]>(`/media-bank-public/material?type=${EMaterialTypes.VIDEO}`, params);
  return data;
};

export const apiFetchVideoById = async (id: IMaterialVideo['id']) => {
  const { data } = await http.get<IMaterialVideo>(`/media-bank/material/${id}?type=${EMaterialTypes.VIDEO}`);
  return data;
};

export const apiFetchPublicVideoById = async (id: IMaterialVideo['id']) => {
  const { data } = await http.get<IMaterialVideo>(`/media-bank-public/material/${id}?type=${EMaterialTypes.VIDEO}`);
  return data;
};
