import { SliceCaseReducers } from '@reduxjs/toolkit';

import { EEntities } from '@core/store/constants';
import { createGenericEntitySlice } from '@core/store/modules/entities/utils/slice';
import { IGenericState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';

import { mapTable } from '@features/dynamicTable/components/DesktopView/mappers';
import { mapMobileData } from '@features/dynamicTable/components/MobileView/mappers';
import { IDynamicTableApi } from '@features/dynamicTable/types';
import { addNumToRows } from '@features/svetofors/DetailingSvetofor/store/mappers';
import { IDetailingSvetoforData } from '@features/svetofors/DetailingSvetofor/store/types';

const endpoint = 'table-public/light?type=np2';

const { slice, thunks, selectors } = createGenericEntitySlice<IDetailingSvetoforData, SliceCaseReducers<IGenericState<IDetailingSvetoforData>>>({
  entity: EEntities.LANDING_HOUSING_COMMISSIONING,
  reducers: {
    resetState(state) {
      state.data = null;
      state.meta = initialMeta;
    },
  },
  thunksConfig: {
    fetchGetEntityThunk: {
      endpoint,
      adapter: (originData: IDynamicTableApi): IDetailingSvetoforData => {
        const data = addNumToRows(originData, false);

        return {
          desktopData: mapTable(data),
          mobileData: mapMobileData(data),
          name: data.name || null,
          districts: data.districts || null,
          date: data.date || null,
        };
      },
    },
  },
});

const {
  fetchGetEntityThunk: fetchHousingCommissioning,
} = thunks;

const {
  selectSliceState: selectHousingCommissioning,
} = selectors;

const { resetState } = slice.actions;

export {
  fetchHousingCommissioning,
  selectHousingCommissioning,
  resetState,
};

export default slice.reducer;
