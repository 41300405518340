import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAlbum } from '@core/models/media';
import http from '@core/services/Http';
import { ENameSpaces } from '@core/store/constants';
import { IGenericMetaState, TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { setFulfilledMetaState, setFulfilledState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

export const fetchAlbumItem = createAsyncThunk<IAlbum, number>(
  `${ENameSpaces.ALBUM_ITEM}/get`,
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await http.get<IAlbum>(`media-bank-public/album/${id}`);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

interface IAlbumItemState {
  data: IAlbum | null;
  meta: IGenericMetaState;
}

const initialState: IAlbumItemState = {
  data: null,
  meta: initialMeta,
};

const albumItemSlice = createSlice({
  name: ENameSpaces.ALBUM_ITEM,
  initialState,
  reducers: {
    setAlbumItem(state, action: PayloadAction<IAlbum | null>) {
      state.data = action.payload;
      setFulfilledMetaState(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAlbumItem.pending, setPendingState);
    builder.addCase(fetchAlbumItem.fulfilled, setFulfilledState);
    builder.addCase(fetchAlbumItem.rejected, setRejectedState);
  },
});

export const selectAlbumItem = (rootState: TRootState): IAlbumItemState => rootState[ENameSpaces.ALBUM_ITEM];

export const { setAlbumItem } = albumItemSlice.actions;

export default albumItemSlice.reducer;
