import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IGenericMetaState, TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { getFulfilledMeta, getPendingMeta, getRejectedMeta } from '@core/store/utils/stateSetters';

import { apiUploadDynamicTable } from '@features/adminPanel/components/DynamicTableUpload/api';
import {
  IDynamicTableAdminState,
  IUploadDynamicTablePayloadApiModel,
} from '@features/adminPanel/components/DynamicTableUpload/types';

export const uploadDynamicTableData = createAsyncThunk<void, IUploadDynamicTablePayloadApiModel>(
  'dynamicTableAdmin/upload',
  async (payload, { rejectWithValue }) => {
    try {
      const payloadFormData = new FormData();
      payloadFormData.append('file', payload.file);

      await apiUploadDynamicTable(payloadFormData);
    } catch (error) {
      return rejectWithValue('rejected');
    }
  },
);

const initialState: IDynamicTableAdminState = {
  uploadMeta: initialMeta,
};

export const dynamicTableAdminSlice = createSlice({
  name: 'dynamicTableAdmin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadDynamicTableData.pending, (state) => {
      state.uploadMeta = getPendingMeta();
    });
    builder.addCase(uploadDynamicTableData.fulfilled, (state) => {
      state.uploadMeta = getFulfilledMeta();
    });
    builder.addCase(uploadDynamicTableData.rejected, (state) => {
      state.uploadMeta = getRejectedMeta();
    });
  },
});

export const selectDynamicTableUploadMeta = ({ dynamicTableAdmin }: TRootState): IGenericMetaState => dynamicTableAdmin.uploadMeta;

export default dynamicTableAdminSlice.reducer;
