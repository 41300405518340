import { cloneDeep, uniqueId } from 'lodash';

import { formatFootnoteNo } from '@features/dynamicTable/helpers/format';
import { IDynamicTable, IDynamicTableApi, IDynamicTableApiCell, IDynamicTableRow, IDynamicTableRowCoords, ISubValue } from '@features/dynamicTable/types';

export const mapSubValues = (cell: IDynamicTableApiCell): ISubValue[] | null => {
  const { secondaryValue, thirdValue, color, secondColor } = cell;

  const values = [];

  if (secondaryValue && secondaryValue.trim() !== '') {
    values.push({ id: uniqueId(), value: secondaryValue, color });
  }

  if (thirdValue && thirdValue.trim() !== '') {
    values.push({ id: uniqueId(), value: thirdValue, color: secondColor });
  }

  return values.length > 0 ? values : null;
};

export const mapCells = (cells: IDynamicTableApiCell[], num?: IDynamicTableRow['num']): IDynamicTableRow['cells'] => cells.map((cell, index) => ({
  id: uniqueId(),
  value: (num && index === 0) ? `${num}. ${cell.value}` : cell.value,
  subValues: mapSubValues(cell),
  footnoteNo: formatFootnoteNo(cell.footnoteNo),
  colspan: cell.colspan,
  rowspan: cell.rowspan,
  x: cell.x || null,
  y: cell.y || null,
  color: cell.color || null,
}));

export const mapTable = (tableMock: IDynamicTableApi): IDynamicTable => {
  const result: IDynamicTable = {
    header: [],
    rows: [],
  };

  const { rows } = tableMock;
  let parentRow: IDynamicTableRow | null = null;
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const nextRow = rows[i + 1];
    const isHeader = row.cells.some((cell) => cell.header);

    if (isHeader) {
      result.header.push({
        id: uniqueId(),
        cells: mapCells(row.cells),
        num: row.num || null,
        subRows: [],
        divider: row.divider,
      });
    } else {
      if (row.depth === 0) {
        parentRow = {
          id: uniqueId(),
          cells: mapCells(row.cells, row.num),
          num: row.num || null,
          subRows: [],
          divider: row.divider,
        };

        if (!nextRow || nextRow.depth === 0) {
          result.rows.push(parentRow);
        }
      }

      if (parentRow !== null && row.depth > 0) {
        parentRow.subRows?.push({
          id: uniqueId(),
          cells: mapCells(row.cells),
          num: row.num,
          divider: row.divider,
        });

        if (!nextRow || nextRow.depth === 0) {
          result.rows.push(parentRow);
          parentRow = null;
        }
      }
    }
  }

  return result;
};

export const buildGrid = (header: IDynamicTableRow[]): { header: IDynamicTableRowCoords[]; headerMaxX: number; headerMaxY: number; } => {
  const copiedHeader: IDynamicTableRowCoords[] = cloneDeep(header);

  const maxX = header[0].cells.reduce((pv, cv) => pv + cv.colspan, 0);
  const maxY = header.length;

  const grid = Array(maxY).fill(undefined)
    .map(() => Array(maxX).fill(undefined));

  let gridY = 0;
  let headerY = 0;
  const maxHY = header.length;
  while (headerY < maxHY) {
    let gridX = 0;
    let headerX = 0;
    const maxHX = header[headerY].cells.length;
    while (headerX < maxHX) {
      // находим следующий незанятый элемент в grid
      while (grid[gridY][gridX] !== undefined) {
        gridX += 1;
      }
      if (grid[gridY][gridX] === undefined) {
        const { colspan, rowspan, value } = header[headerY].cells[headerX];
        // добавляем ячейки по колонкам, учитывая colspan
        grid[gridY].splice(gridX, colspan, ...Array(colspan).fill(value));

        // добавляем ячейки в следующие столбцы, учитывая rowspan
        if (rowspan > 1) {
          for (let i = 1; i < rowspan; i++) {
            grid[gridY + i].splice(gridX, colspan, ...Array(colspan).fill(value));
          }
        }

        // добавляем координаты в ячейку
        copiedHeader[headerY].cells[headerX].gridCoords = {
          xStart: gridX,
          xEnd: gridX + colspan,
          yStart: gridY,
          yEnd: gridY + rowspan,
        };

        headerX += 1;
      }
    }
    gridY += 1;
    headerY += 1;
  }

  return { header: copiedHeader, headerMaxX: maxX, headerMaxY: maxY };
};
