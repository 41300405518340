import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { INews } from '@core/models/news';
import http from '@core/services/Http';
import { TRootState } from '@core/store/types';
import { setFulfilledState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

export const apiFetchNewsItem = async (id: number): Promise<INews> => {
  const { data } = await http.get<INews>(`news/${id}`);
  return data;
};

export const fetchNewsItem = createAsyncThunk<INews, number>(
  'news/fetchItem',
  async (id: number, { rejectWithValue }) => {
    try {
      return await apiFetchNewsItem(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

interface INewsItemState {
  data: INews | null;
  meta: {
    fetchLoading: false,
    fetchSuccess: false,
    fetchError: false,
  },
}

const initialState: INewsItemState = {
  data: null,
  meta: {
    fetchLoading: false,
    fetchSuccess: false,
    fetchError: false,
  },
};

export const newsItemSlice = createSlice({
  name: 'newsItem',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNewsItem.pending, setPendingState);
    builder.addCase(fetchNewsItem.fulfilled, setFulfilledState);
    builder.addCase(fetchNewsItem.rejected, setRejectedState);
  },
});

export const selectNewsItem = ({ newsItem }: TRootState): INewsItemState => newsItem;

export default newsItemSlice.reducer;
