import { createContext, useCallback, useContext, useState } from 'react';

import { EDrawerNames } from '@core/constants/drawerNames';

import { IDrawerContext, IDrawerProviderProps } from './types';

export const DrawerContext = createContext<IDrawerContext>({
  openDrawer: () => {},
  closeDrawer: () => {},
  isOpen: () => false,
  isClose: () => false,
  list: [],
});

export const useDrawer = (): IDrawerContext => useContext(DrawerContext);

export const DrawerProvider = (props: IDrawerProviderProps) => {
  const { children } = props;
  const [list, setList] = useState<string[]>([]);

  const isOpen = useCallback((name: EDrawerNames) => list.includes(name), [list]);
  const isClose = useCallback((name: EDrawerNames) => !list.includes(name), [list]);

  const openDrawer = useCallback((name: EDrawerNames) => {
    if (isClose(name)) {
      setList([...list, name]);
    }
  }, [isClose, list]);

  const closeDrawer = useCallback((name: EDrawerNames) => {
    if (isOpen(name)) {
      setList(list.filter((drawerName) => drawerName !== name));
    }
  }, [isOpen, list]);

  return (
    <DrawerContext.Provider value={{ list, isOpen, isClose, openDrawer, closeDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};
