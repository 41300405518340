import { SliceCaseReducers, ValidateSliceCaseReducers, createSlice } from '@reduxjs/toolkit';

import { EEntities } from '@core/store/constants';
import {
  createFetchGetEntityThunk,
  createFetchCreateEntityThunk,
  createFetchUpdateEntityThunk,
  createFetchDeleteEntityThunk,
} from '@core/store/modules/entities/utils/thunks';
import { TSliceThunksConfig, IGenericEntitySlice } from '@core/store/modules/entities/utils/types';
import { IGenericState, TRootState } from '@core/store/types';
import { createGenericState } from '@core/store/utils/createGenericState';
import {
  setGenericFulfilledMetaState,
  setGenericFulfilledState,
  setGenericPendingState,
  setGenericRejectedState,
} from '@core/store/utils/stateSetters';

export const createGenericEntitySlice = <T, Reducers extends SliceCaseReducers<IGenericState<T>>>({
  entity,
  initialState = createGenericState<T>(),
  reducers,
  thunksConfig = {},
}: {
  entity: EEntities;
  initialState?: IGenericState<T>;
  reducers?: ValidateSliceCaseReducers<IGenericState<T>, Reducers>;
  thunksConfig?: TSliceThunksConfig
}): IGenericEntitySlice<T> => {
  const thunks = {
    fetchGetEntityThunk: createFetchGetEntityThunk<T>(entity, thunksConfig.fetchGetEntityThunk),
    fetchCreateEntityThunk: createFetchCreateEntityThunk<T>(entity, thunksConfig.fetchCreateEntityThunk),
    fetchUpdateEntityThunk: createFetchUpdateEntityThunk<T>(entity, thunksConfig.fetchUpdateEntityThunk),
    fetchDeleteEntityThunk: createFetchDeleteEntityThunk<T>(entity, thunksConfig.fetchDeleteEntityThunk),
  };

  const slice = createSlice({
    name: entity,
    initialState,
    reducers: reducers || {},
    extraReducers: (builder) => {
      Object.values(thunks).forEach((thunk) => {
        builder.addCase(thunk.pending, setGenericPendingState);
        builder.addCase(thunk.rejected, setGenericRejectedState);
      });

      builder.addCase(thunks.fetchGetEntityThunk.fulfilled, setGenericFulfilledState);

      builder.addCase(thunks.fetchCreateEntityThunk.fulfilled, setGenericFulfilledState);

      builder.addCase(thunks.fetchUpdateEntityThunk.fulfilled, setGenericFulfilledState);

      builder.addCase(thunks.fetchDeleteEntityThunk.fulfilled, (state) => {
        state.data = null;
        setGenericFulfilledMetaState(state);
      });
    },
  });

  type TSliceState = ReturnType<typeof slice.reducer>;

  const selectSliceState = ({ entities }: TRootState): TSliceState => entities[entity];

  return {
    slice,
    selectors: {
      selectSliceState,
    },
    thunks,
  };
};
