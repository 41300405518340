import http from '@core/services/Http';

import { IDiscussion } from '@features/materials/components/Discussions/types';

export const apiFetchDiscussion = async (id: IDiscussion['id']): Promise<IDiscussion> => {
  const { data } = await http.get<IDiscussion>(`discussion/${id}`);
  return data;
};

export const apiDeleteDiscussion = async (id: IDiscussion['id']): Promise<void> => {
  await http.delete(`discussion/${id}`);
};
