import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import http from '@core/services/Http';
import { ENameSpaces } from '@core/store/constants';
import { IFileInfo } from '@core/store/modules/materials/types';
import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { setFulfilledState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

export const fetchLegalDocuments = createAsyncThunk<IFileInfo[], any>(
  `${ENameSpaces.LEGAL_DOCUMENTS}/get`, async (params, { rejectWithValue }) => {
    try {
      const { isPublic } = params;
      const { data } = await http.get<IFileInfo[]>(
        isPublic ? '/file-public' : '/file',
        params,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const legalDocumentsSlice = createSlice({
  name: ENameSpaces.LEGAL_DOCUMENTS,
  initialState: {
    data: [],
    meta: initialMeta,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLegalDocuments.pending, setPendingState);
    builder.addCase(fetchLegalDocuments.rejected, setRejectedState);
    builder.addCase(fetchLegalDocuments.fulfilled, setFulfilledState);
  },
});

type TSliceState = ReturnType<typeof legalDocumentsSlice.reducer>;

export const selectLegalDocuments = ({ legalDocuments }: TRootState): TSliceState => legalDocuments;

export default legalDocumentsSlice.reducer;
