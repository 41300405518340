import { INews } from '@core/models/news';
import { ECollections } from '@core/store/constants';
import { createGenericCollectionSlice } from '@core/store/modules/collections/utils/slice';

const AMOUNT_OF_HIGH_NEWS = 2;

const { slice, thunks, selectors } = createGenericCollectionSlice<INews, any>({
  collection: ECollections.LANDING_HIGH_NEWS,
  thunksConfig: {
    fetchGetCollectionThunk: {
      endpoint: '/news-public',
      adapter: (response) => ({ items: response.slice(0, AMOUNT_OF_HIGH_NEWS) }),
    },
  },
});

const { fetchGetCollectionThunk: fetchLandingHighNews } = thunks;

const { selectSliceState: selectLandingHighNews } = selectors;

export { fetchLandingHighNews, selectLandingHighNews };

export default slice.reducer;
