import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import legalDocuments from '@core/components/AntiCrisisProgramComponents/RegulatoryWork/components/LegalDocuments/store';
import studyMaterials from '@core/components/AntiCrisisProgramComponents/RegulatoryWork/components/StudyMaterials/store';
import pollsReducer from '@core/components/Polls/store';
import { ENameSpaces } from '@core/store/constants';
import userLog from '@core/store/modules/adminPanel/userLog/store';
import collectionsReducers from '@core/store/modules/collections';
import dashboardsReducer from '@core/store/modules/dashboards/store';
import entitiesReducers from '@core/store/modules/entities';
import feedback from '@core/store/modules/feedback/store';
import feedbackDictionaries from '@core/store/modules/feedbackDictionaries/store';
import materials from '@core/store/modules/materials/store';
import placeholders from '@core/store/modules/placeholders/store';
import svetofors from '@core/store/modules/svetofors/store';

import antiCrisisMonitoringUpload from '@features/adminPanel/components/AntiCrisisMonitoringUpload/store';
import dynamicTableAdmin from '@features/adminPanel/components/DynamicTableUpload/store';
import infrastructureMenuUpload from '@features/adminPanel/components/InfrastructureMenuUpload/store';
import mediaReducer from '@features/adminPanel/components/Media/store';
import monitoringUpload from '@features/adminPanel/components/MonitoringDataUpload/store';
import extendedPolls from '@features/adminPanel/components/Polls/store';
import antiCrisisMonitoring from '@features/antiCrisisMonitoring/store/antiCrisisMonitoring';
import authentication from '@features/authentication/store';
import myDictionary from '@features/encyclopedia/components/MyDictionary/store';
import encyclopedia from '@features/encyclopedia/store';
import infrastructureMenuWidgets from '@features/infrastructureMenu/store/widgets';
import publicNewsModal from '@features/landing/store/publicNewsModal';
import discussionComments from '@features/materials/components/Discussion/components/Comments/store';
import discussion from '@features/materials/components/Discussion/components/DiscussionTopic/store';
import discussionFiles from '@features/materials/components/Discussion/components/Documents/store';
import discussions from '@features/materials/components/Discussions/store';
import albumItemReducer from '@features/media-bank/photos/store/albumItem';
import albumsReducer from '@features/media-bank/photos/store/albums';
import videoItemReducer from '@features/media-bank/videoArchive/store/video';
import videoArchiveReducer from '@features/media-bank/videoArchive/store/videoArchive';
import news from '@features/news/NewsList/store/news';
import newsItem from '@features/news/NewsPage/store';

const combinedReducer = combineReducers({
  [ENameSpaces.COLLECTIONS]: collectionsReducers,
  [ENameSpaces.ENTITIES]: entitiesReducers,
  [ENameSpaces.AUTHENTICATION]: authentication,
  [ENameSpaces.DASHBOARDS]: dashboardsReducer,
  [ENameSpaces.FEEDBACK_DICTIONARIES]: feedbackDictionaries,
  [ENameSpaces.FEEDBACK]: feedback,
  [ENameSpaces.USER_LOG]: userLog,
  [ENameSpaces.MATERIALS]: materials,
  [ENameSpaces.NEWS]: news,
  [ENameSpaces.NEWS_ITEM]: newsItem,
  [ENameSpaces.SVETOFORS]: svetofors,
  [ENameSpaces.DYNAMIC_TABLE_ADMIN]: dynamicTableAdmin,
  [ENameSpaces.DISCUSSIONS]: discussions,
  [ENameSpaces.DISCUSSION]: discussion,
  [ENameSpaces.DISCUSSION_FILES]: discussionFiles,
  [ENameSpaces.DISCUSSION_COMMENTS]: discussionComments,
  [ENameSpaces.MONITORING_UPLOAD]: monitoringUpload,
  [ENameSpaces.INFRASTRUCTURE_MENU_WIDGETS]: infrastructureMenuWidgets,
  [ENameSpaces.INFRASTRUCTURE_MENU_UPLOAD]: infrastructureMenuUpload,
  [ENameSpaces.ANTI_CRISIS_MONITORING]: antiCrisisMonitoring,
  [ENameSpaces.LEGAL_DOCUMENTS]: legalDocuments,
  [ENameSpaces.STUDY_MATERIALS]: studyMaterials,
  [ENameSpaces.PUBLIC_NEWS_ITEM]: publicNewsModal,
  [ENameSpaces.POLLS]: pollsReducer,
  [ENameSpaces.ALBUMS]: albumsReducer,
  [ENameSpaces.ALBUM_ITEM]: albumItemReducer,
  [ENameSpaces.MEDIA]: mediaReducer,
  [ENameSpaces.ENCYCLOPEDIA]: encyclopedia,
  [ENameSpaces.MY_DICTIONARY]: myDictionary,
  [ENameSpaces.EXTENDED_POLLS]: extendedPolls,
  [ENameSpaces.ANTI_CRISIS_MONITORING_UPLOAD]: antiCrisisMonitoringUpload,
  [ENameSpaces.VIDEO_ARCHIVE]: videoArchiveReducer,
  [ENameSpaces.VIDEO_ITEM]: videoItemReducer,
  [ENameSpaces.PLACEHOLDERS]: placeholders,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
    } as ReturnType<typeof combinedReducer>;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
