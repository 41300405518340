import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import http from '@core/services/Http';
import { ENameSpaces } from '@core/store/constants';
import { IFileInfo } from '@core/store/modules/materials/types';
import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { setFulfilledState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

export const fetchStudyMaterials = createAsyncThunk<IFileInfo[], any>(
  `${ENameSpaces.STUDY_MATERIALS}/get`, async (params, { rejectWithValue }) => {
    try {
      const { isPublic } = params;
      const { data } = await http.get<IFileInfo[]>(
        isPublic ? '/file-public?type=study' : '/file?type=study',
        params,
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const studyMaterialsSlice = createSlice({
  name: ENameSpaces.STUDY_MATERIALS,
  initialState: {
    data: [],
    meta: initialMeta,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStudyMaterials.pending, setPendingState);
    builder.addCase(fetchStudyMaterials.rejected, setRejectedState);
    builder.addCase(fetchStudyMaterials.fulfilled, setFulfilledState);
  },
});

type TSliceState = ReturnType<typeof studyMaterialsSlice.reducer>;

export const selectStudyMaterials = ({ studyMaterials }: TRootState): TSliceState => studyMaterials;

export default studyMaterialsSlice.reducer;
