import http from '@core/services/Http';

import {
  ISignInPayloadApiModel,
  IAuthApiResponse,
  IRefreshJWTPayload,
} from '@features/authentication/models';

export const apiFetchSignIn = async (payload: ISignInPayloadApiModel): Promise<IAuthApiResponse> => {
  const { data } = await http.post<IAuthApiResponse>('session', payload);
  return data;
};

export const apiRefreshJWT = async (payload: IRefreshJWTPayload): Promise<IAuthApiResponse> => {
  const { data } = await http.post<IAuthApiResponse>('session/refresh', payload);
  return data;
};
