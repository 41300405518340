import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { EPlaceholderIds } from '@core/constants/placeholders';
import { IPlaceholder } from '@core/models/placeholder';
import { ENameSpaces } from '@core/store/constants';
import { apiFetchDeleteFile } from '@core/store/modules/materials/api';
import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { getFulfilledMeta, getPendingMeta, getRejectedMeta, setFulfilledState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';
import { uploadSingleFile } from '@core/utils/uploadFile';

import { apiFetchPlaceholderById, apiFetchPlaceholders, apiUpdatePlaceholderById } from './api';
import { IPlaceholdersState, IUpdatePlaceholders } from './types';

export const fetchPlaceholders = createAsyncThunk<IPlaceholder[], void>(
  `${ENameSpaces.PLACEHOLDERS}/fetch`,
  async (_, { rejectWithValue }) => {
    try {
      return await apiFetchPlaceholders();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updatePlaceholders = createAsyncThunk<PromiseSettledResult<unknown>[], IUpdatePlaceholders>(
  `${ENameSpaces.PLACEHOLDERS}/update`,
  async ({ formValues, initialFormValues }, { rejectWithValue }) => {
    try {
      return await Promise.allSettled(Object.entries(formValues).map(async ([id, current]) => {
        const { file: [file], isActive } = current;
        const initialFile = initialFormValues[id].file[0];

        if (file instanceof File) {
          const [{ data }] = await Promise.all([
            uploadSingleFile({ file, type: 'common', isPublic: true }),
          ]);

          if (initialFile) {
            return apiFetchDeleteFile(initialFile.id)
              .catch(() => {})
              .finally(() => {
                apiUpdatePlaceholderById(id, { fileId: data.id, isActive });
              });
          }
        }

        return apiUpdatePlaceholderById(id, { isActive: current.isActive });
      }));
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPlaceholdersByIds = createAsyncThunk<IPlaceholder[], IPlaceholder['id'][]>(
  `${ENameSpaces.PLACEHOLDERS}/fetch-item`,
  async (ids, { rejectWithValue }) => {
    try {
      return await Promise.all(ids.map(apiFetchPlaceholderById));
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IPlaceholdersState = {
  data: [],
  meta: initialMeta,
  updateMeta: initialMeta,
  collection: {},
};

const placeholdersSlice = createSlice({
  name: ENameSpaces.PLACEHOLDERS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlaceholders.pending, setPendingState);
    builder.addCase(fetchPlaceholders.rejected, setRejectedState);
    builder.addCase(fetchPlaceholders.fulfilled, setFulfilledState);

    builder.addCase(updatePlaceholders.pending, (state) => {
      state.updateMeta = getPendingMeta();
    });
    builder.addCase(updatePlaceholders.rejected, (state) => {
      state.updateMeta = getRejectedMeta();
    });
    builder.addCase(updatePlaceholders.fulfilled, (state) => {
      state.updateMeta = getFulfilledMeta();
    });

    builder.addCase(fetchPlaceholdersByIds.fulfilled, (state, { payload }) => {
      payload.forEach((item) => {
        state.collection[item.id] = item;
      });
    });
  },
});

export const selectPlaceholders = (rootState: TRootState) => rootState[ENameSpaces.PLACEHOLDERS];
export const selectPlaceholderById = (id: EPlaceholderIds) => (rootState: TRootState) => rootState[ENameSpaces.PLACEHOLDERS].collection[id];

export default placeholdersSlice.reducer;
