import { EEntities } from '@core/store/constants';
import { createGenericEntitySlice } from '@core/store/modules/entities/utils/slice';

import { IEntityModel } from '@features/entity/models';

const { slice, thunks, selectors } = createGenericEntitySlice<IEntityModel, any>({ entity: EEntities.ENTITY });

const {
  fetchGetEntityThunk: fetchGetEntity,
  fetchCreateEntityThunk: fetchCreateEntity,
  fetchUpdateEntityThunk: fetchUpdateEntity,
  fetchDeleteEntityThunk: fetchDeleteEntity,
} = thunks;

const {
  selectSliceState: selectEntityState,
} = selectors;

export {
  fetchGetEntity,
  fetchCreateEntity,
  fetchUpdateEntity,
  fetchDeleteEntity,
  selectEntityState,
};

export default slice.reducer;
