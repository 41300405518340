import { combineReducers } from '@reduxjs/toolkit';

import { ECollections } from '@core/store/constants';

import regionsReducer from '@features/adminPanel/components/News/store/regions';
import collectionReducer from '@features/collection/store';
import landingHighNewsReducer from '@features/landing/store/landingHighNews';
import landingNewsReducer from '@features/landing/store/landingNews';
import landingTopNewsReducer from '@features/landing/store/landingTopNews';
import districtsReducer from '@features/news/NewsList/store/districts';
import pinnedNewsReducer from '@features/news/NewsList/store/pinnedNews';

const reducers = combineReducers({
  [ECollections.COLLECTION]: collectionReducer,
  [ECollections.DISTRICTS]: districtsReducer,
  [ECollections.LANDING_HIGH_NEWS]: landingHighNewsReducer,
  [ECollections.LANDING_NEWS]: landingNewsReducer,
  [ECollections.LANDING_TOP_NEWS]: landingTopNewsReducer,
  [ECollections.PINNED_NEWS]: pinnedNewsReducer,
  [ECollections.REGIONS]: regionsReducer,
});

export default reducers;
