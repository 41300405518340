import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IPoll } from '@core/models/poll';
import http from '@core/services/Http';
import { ENameSpaces } from '@core/store/constants';
import { TRootState } from '@core/store/types';
import { createGenericState } from '@core/store/utils/createGenericState';
import { setGenericFulfilledMetaState, setGenericFulfilledState, setGenericPendingState, setGenericRejectedState } from '@core/store/utils/stateSetters';

import { IFetchPollsArg, ISendPollAnswersArg } from './types';
import { persistCompletedPublicPoll } from './utils';

export const sendPollAnswers = createAsyncThunk<IPoll, ISendPollAnswersArg>(
  `${ENameSpaces.POLLS}/sendAnswers`,
  async ({ answers, isPublic, pollId }, { rejectWithValue }) => {
    try {
      await Promise.all(
        answers.map(
          (answer) => http.post(
            `poll-${isPublic ? 'public' : 'private'}/vote/${answer}`,
            {},
          ),
        ),
      );

      const { data } = await http.get<IPoll>(`poll-${isPublic ? 'public' : 'private'}/${pollId}`);

      if (isPublic) {
        persistCompletedPublicPoll(pollId);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchPolls = createAsyncThunk<IPoll[], IFetchPollsArg>(
  `${ENameSpaces.POLLS}/getAll`,
  async ({ isPublic }, { rejectWithValue }) => {
    try {
      const { data } = await http.get<IPoll[]>(`poll-${isPublic ? 'public' : 'private'}`);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const pollsSlice = createSlice({
  name: ENameSpaces.POLLS,
  initialState: createGenericState<IPoll[]>(),
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchPolls.pending, setGenericPendingState);
    builder.addCase(fetchPolls.rejected, setGenericRejectedState);
    builder.addCase(fetchPolls.fulfilled, setGenericFulfilledState);

    builder.addCase(sendPollAnswers.pending, setGenericPendingState);
    builder.addCase(sendPollAnswers.rejected, setGenericRejectedState);
    builder.addCase(sendPollAnswers.fulfilled, (state, { payload }) => {
      setGenericFulfilledMetaState(state);

      state.data = state.data ? state.data.map(
        (poll) => (poll.id === payload.id ? { ...payload, complete: true } : poll),
      ) : null;
    });
  },
});

type TSliceState = ReturnType<typeof pollsSlice.reducer>;

export const selectPolls = (rootState: TRootState): TSliceState => rootState[ENameSpaces.POLLS];

export default pollsSlice.reducer;
