import { getLocalStorageItem, setLocalStorageItem } from '@core/utils/localStorage';

const COMPLETED_PUBLIC_POLLS_KEY = 'cpp';
const HIDDEN_PUBLIC_POLLS_KEY = 'hpup';
const HIDDEN_PRIVATE_POLLS_KEY = 'hprp';

export function getHiddenPolls(isPublic?: boolean) {
  return getLocalStorageItem<number[]>(
    isPublic ? HIDDEN_PUBLIC_POLLS_KEY : HIDDEN_PRIVATE_POLLS_KEY,
  ) || [];
}

export function getCompletedPublicPolls() {
  return getLocalStorageItem<number[]>(COMPLETED_PUBLIC_POLLS_KEY) || [];
}

export function persistCompletedPublicPoll(pollId: number) {
  const completedPolls = getLocalStorageItem<number[]>(COMPLETED_PUBLIC_POLLS_KEY) || [];

  setLocalStorageItem(COMPLETED_PUBLIC_POLLS_KEY, [...completedPolls, pollId]);
}

export function persistHiddenPoll(pollId: number, isPublic?: boolean) {
  const hiddenPolls = getHiddenPolls(isPublic);

  setLocalStorageItem(
    isPublic ? HIDDEN_PUBLIC_POLLS_KEY : HIDDEN_PRIVATE_POLLS_KEY,
    [...hiddenPolls, pollId],
  );
}
