import http from '@core/services/Http';
import { TInterceptor } from '@core/services/Http/types';

let authRequestInterceptor: TInterceptor = 0;
export const addAuthorizationInterceptor = (token: string): void => {
  http.ejectRequestInterceptor(authRequestInterceptor);
  authRequestInterceptor = http.setRequestInterceptors((config) => {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};
