import { IGenericMetaState } from '@core/store/types';

import { IDiscussion, IDiscussionsList } from './types';

export interface IDiscussionsState {
  data: IDiscussionsList;
  params: IFetchDiscussionsParams;
  meta: IGenericMetaState;
  editMeta: IGenericMetaState;
}

export interface IDiscussionEditPayload {
  title: IDiscussion['title'];
  text: IDiscussion['text'];
  file?: File | null;
}

export interface IFetchDiscussionsParams {
  sorting?: EDiscussionSorting;
  pagination: IPagination;
}

export enum EDiscussionSorting {
  NEW = 'new',
  OLD = 'old',
  LAST_COMMENTED = 'last_commented',
  MOST_COMMENTED = 'most_commented',
}

export interface IPagination {
  limit: number;
  offset: number;
  isLastPage: boolean;
}
