import { EEntities } from '@core/store/constants';
import { createGenericEntitySlice } from '@core/store/modules/entities/utils/slice';

import { ILights } from '../types';

const { slice, thunks, selectors } = createGenericEntitySlice<ILights, {}>({
  entity: EEntities.LIGHTS,
  thunksConfig: {
    fetchGetEntityThunk: {
      endpoint: '/table/lights-for-map',
    },
  },
});

const { fetchGetEntityThunk: fetchLights } = thunks;

const { selectSliceState: selectLights } = selectors;

export { fetchLights, selectLights };

export default slice.reducer;
