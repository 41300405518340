import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IGenericMetaState, TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import {
  getFulfilledMeta,
  getPendingMeta,
  getRejectedMeta,
  setFulfilledState,
  setPendingState, setRejectedState,
} from '@core/store/utils/stateSetters';

import { apiDeleteDiscussion, apiFetchDiscussion } from '@features/materials/components/Discussion/components/DiscussionTopic/api';
import { IDiscussionState } from '@features/materials/components/Discussion/components/DiscussionTopic/model';
import { IDiscussion } from '@features/materials/components/Discussions/types';

export const fetchDiscussion = createAsyncThunk<IDiscussion, IDiscussion['id']>(
  'discussion/fetch',
  async (discussionId, { rejectWithValue }) => {
    try {
      return await apiFetchDiscussion(discussionId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteDiscussion = createAsyncThunk<void, IDiscussion['id']>(
  'discussion/delete',
  async (discussionId, { rejectWithValue }) => {
    try {
      await apiDeleteDiscussion(discussionId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IDiscussionState = {
  data: null,
  meta: initialMeta,
  deleteMeta: initialMeta,
};

export const discussionSlice = createSlice({
  name: 'discussion',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetDeleteMeta(state) {
      state.deleteMeta = initialMeta;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDiscussion.pending, setPendingState);
    builder.addCase(fetchDiscussion.fulfilled, setFulfilledState);
    builder.addCase(fetchDiscussion.rejected, setRejectedState);

    builder.addCase(deleteDiscussion.pending, (state) => {
      state.deleteMeta = getPendingMeta();
    });
    builder.addCase(deleteDiscussion.fulfilled, (state) => {
      state.deleteMeta = getFulfilledMeta();
    });
    builder.addCase(deleteDiscussion.rejected, (state) => {
      state.deleteMeta = getRejectedMeta();
    });
  },
});

const { resetState, resetDeleteMeta } = discussionSlice.actions;
export { resetState, resetDeleteMeta };

export const selectDiscussionState = ({ discussion }: TRootState): IDiscussionState => discussion;
export const selectDiscussionDeleteMeta = ({ discussion }: TRootState): IGenericMetaState => discussion.deleteMeta;

export default discussionSlice.reducer;
