import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { INews } from '@core/models/news';
import http from '@core/services/Http';
import { ENameSpaces } from '@core/store/constants';
import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { setFulfilledMetaState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

import { RF_REGION_ID } from '@features/news/NewsList/constants';

import { DEFAULT_LIMIT, API_PATH } from './constants';
import { IFetchNewsArg, INewsState, TCreateNewsArg } from './types';

const initialState: INewsState = {
  data: [],
  fetchedAll: false,
  meta: initialMeta,
  params: {
    offset: 0,
    limit: DEFAULT_LIMIT,
    region: RF_REGION_ID,
    text: '',
  },
  filteredByQuery: false,
};

export const fetchNews = createAsyncThunk<INews[], IFetchNewsArg>(
  `${ENameSpaces.NEWS}/get`, async ({
    offset = 0,
    limit = DEFAULT_LIMIT,
    region = RF_REGION_ID,
    text,
  }, { rejectWithValue }) => {
    try {
      const { data } = await http.get<INews[]>(API_PATH, {
        offset,
        limit,
        region: region === RF_REGION_ID ? undefined : region,
        ...text && { text },
      });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createNews = createAsyncThunk<INews, TCreateNewsArg>(
  `${ENameSpaces.NEWS}/create`, async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.post<INews>(API_PATH, payload);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateNews = createAsyncThunk<INews, INews>(
  `${ENameSpaces.NEWS}/update`, async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.put<INews>(`${API_PATH}/${payload.id}`, payload);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteNews = createAsyncThunk<number, number>(
  `${ENameSpaces.NEWS}/delete`, async (id, { rejectWithValue }) => {
    try {
      await http.delete<INews>(`${API_PATH}/${id}`);

      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const newsSlice = createSlice({
  name: ENameSpaces.NEWS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNews.pending, (state, { meta }) => {
      const { arg: {
        region = RF_REGION_ID,
        limit = DEFAULT_LIMIT,
        offset = 0,
        text = '',
      } } = meta;

      state.params = { offset, limit, region, text };
      setPendingState(state);
    });
    builder.addCase(fetchNews.rejected, setRejectedState);
    builder.addCase(fetchNews.fulfilled, (state, { payload, meta }) => {
      const { arg: { merge, text } } = meta;

      state.data = merge ? state.data.concat(payload) : payload;
      state.fetchedAll = payload.length < state.params.limit;
      state.filteredByQuery = Boolean(text);

      setFulfilledMetaState(state);
    });

    builder.addCase(createNews.pending, setPendingState);
    builder.addCase(createNews.rejected, setRejectedState);
    builder.addCase(createNews.fulfilled, (state, { payload }) => {
      state.data = [payload, ...state.data];

      state.params.limit = state.data.length;
      state.params.offset = 0;

      setFulfilledMetaState(state);
    });

    builder.addCase(updateNews.pending, setPendingState);
    builder.addCase(updateNews.rejected, setRejectedState);
    builder.addCase(updateNews.fulfilled, (state, { payload: updatedNews }) => {
      state.data = state.data.map(
        (news) => (news.id === updatedNews.id ? updatedNews : news),
      );

      setFulfilledMetaState(state);
    });

    builder.addCase(deleteNews.pending, setPendingState);
    builder.addCase(deleteNews.rejected, setRejectedState);
    builder.addCase(deleteNews.fulfilled, (state, { payload: deletedNewsId }) => {
      state.data = state.data.filter((news) => news.id !== deletedNewsId);

      state.params.limit = state.data.length;
      state.params.offset = 0;

      setFulfilledMetaState(state);
    });
  },
});

type TSliceState = ReturnType<typeof newsSlice.reducer>;

export const selectNews = ({ news }: TRootState): TSliceState => news;

export default newsSlice.reducer;
