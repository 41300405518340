import { SliceCaseReducers, createSlice, Draft, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import {
  TSliceThunksConfig,
  IGenericCollectionSlice,
} from '@core//store/modules/collections/utils/types';
import { ECollections } from '@core/store/constants';
import {
  createFetchGetCollectionThunk,
  createFetchGetNextPageCollectionThunk,
  createFetchCreateItemInCollectionThunk,
  createFetchUpdateItemInCollectionThunk,
  createFetchDeleteItemInCollectionThunk,
} from '@core/store/modules/collections/utils/thunks';
import { IGenericState, TRootState } from '@core/store/types';
import { createGenericState } from '@core/store/utils/createGenericState';
import {
  setGenericFulfilledMetaState,
  setGenericPendingState,
  setGenericRejectedState,
} from '@core/store/utils/stateSetters';

export const createGenericCollectionSlice = <T extends { id: string | number }, Reducers extends SliceCaseReducers<IGenericState<T[]>>>({
  collection,
  initialState = createGenericState<T[]>(),
  reducers,
  thunksConfig = {},
  addExtraReducers = () => {},
}: {
  collection: ECollections;
  initialState?: IGenericState<T[]>;
  reducers?: Reducers;
  thunksConfig?: TSliceThunksConfig;
  addExtraReducers?: (builder: ActionReducerMapBuilder<IGenericState<T[]>>) => void;
}): IGenericCollectionSlice<T> => {
  const thunks = {
    fetchGetCollectionThunk: createFetchGetCollectionThunk<T>(
      collection,
      thunksConfig.fetchGetCollectionThunk,
    ),
    fetchGetNextPageCollectionThunk: createFetchGetNextPageCollectionThunk<T>(
      collection,
      thunksConfig.fetchGetNextPageCollectionThunk,
    ),
    fetchCreateItemInCollectionThunk: createFetchCreateItemInCollectionThunk<T>(
      collection,
      thunksConfig.fetchCreateItemInCollectionThunk,
    ),
    fetchUpdateItemInCollectionThunk: createFetchUpdateItemInCollectionThunk<T>(
      collection,
      thunksConfig.fetchUpdateItemInCollectionThunk,
    ),
    fetchDeleteItemInCollectionThunk: createFetchDeleteItemInCollectionThunk<T>(
      collection,
      thunksConfig.fetchDeleteItemInCollectionThunk,
    ),
  };

  const slice = createSlice({
    name: collection,
    initialState,
    reducers: {
      ...reducers,
    },
    extraReducers: (builder) => {
      addExtraReducers(builder);

      Object.values(thunks).forEach((thunk) => {
        builder.addCase(thunk.pending, setGenericPendingState);
        builder.addCase(thunk.rejected, setGenericRejectedState);
      });

      builder.addCase(thunks.fetchGetCollectionThunk.fulfilled, (state, { payload }) => {
        const { items, page, size } = payload;

        state.data = items as Draft<T[]>;
        state.meta.page = page;
        state.meta.size = size;

        setGenericFulfilledMetaState(state);
      });

      builder.addCase(thunks.fetchGetNextPageCollectionThunk.fulfilled, (state, { payload }) => {
        state.data = [...state.data as Draft<T[]>, ...payload.items] as Draft<T[]>;

        setGenericFulfilledMetaState(state);

        if (payload.items.length < payload.pageLimit) {
          state.meta.fetchedAll = true;
        }
      });

      builder.addCase(thunks.fetchCreateItemInCollectionThunk.fulfilled, (state, { payload }) => {
        if (!state.data) {
          state.data = [payload as Draft<T>];
        } else {
          state.data.push(payload as Draft<T>);
        }

        setGenericFulfilledMetaState(state);
      });

      builder.addCase(thunks.fetchUpdateItemInCollectionThunk.fulfilled, (state, { payload }) => {
        const { id } = payload;

        if (!state.data) {
          return;
        }

        state.data = state.data.map((item) => {
          if (item?.id !== id) {
            return item;
          }

          return payload as Draft<T>;
        });

        setGenericFulfilledMetaState(state);
      });

      builder.addCase(thunks.fetchDeleteItemInCollectionThunk.fulfilled, (state, { payload }) => {
        const { id } = payload;

        if (!state.data) {
          return;
        }

        state.data = state.data.filter((item) => item?.id !== id);
        setGenericFulfilledMetaState(state);
      });
    },
  });

  type TSliceState = ReturnType<typeof slice.reducer>;

  const selectSliceState = ({ collections }: TRootState): TSliceState => collections[collection];

  return {
    slice,
    selectors: {
      selectSliceState,
    },
    thunks,
  };
};
