import { IPlaceholder } from '@core/models/placeholder';
import http from '@core/services/Http';

import { IApiUpdateParams } from './types';

export const apiFetchPlaceholders = async () => {
  const { data } = await http.get<IPlaceholder[]>('/placeholder');
  return data;
};

export const apiUpdatePlaceholderById = async (id: IPlaceholder['id'], params: IApiUpdateParams) => {
  const { data } = await http.patch(`/placeholder/${id}`, params);
  return data;
};

export const apiFetchPlaceholderById = async (id: IPlaceholder['id']) => {
  const { data } = await http.get<IPlaceholder>(`/placeholder-public/${id}`);
  return data;
};
