import http from '@core/services/Http';
import { EXTENDED_TIMEOUT } from '@core/services/Http/Http';

export const apiUploadInfrastructureMenu = async (payload: FormData): Promise<void> => {
  await http.post('infrastructure-menu', payload, {
    config: {
      timeout: EXTENDED_TIMEOUT,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  });
};
