import http from '@core/services/Http';

import { IFetchDiscussionsParams } from '@features/materials/components/Discussions/model';

import { IDiscussion, IDiscussionsList } from './types';

export const apiFetchDiscussions = async (params: IFetchDiscussionsParams): Promise<IDiscussionsList> => {
  const apiParams = {
    sorting: params.sorting,
    limit: params.pagination.limit,
    offset: params.pagination.offset,
  };

  const { data } = await http.get<IDiscussionsList>('discussion', apiParams);
  return data;
};

export const apiCreateDiscussion = async (payload: FormData): Promise<void> => {
  await http.post('discussion', payload);
};

export const apiEditDiscussion = async (id: IDiscussion['id'], payload: FormData): Promise<void> => {
  await http.put(`discussion/${id}`, payload);
};
