import { differenceBy, isEqual, partition } from 'lodash';

import { IExtendedPoll, IExtendedQuestion, IQuestion, IAnswer, IExtendedAnswer } from '@core/models/poll';

import { IQuestionField, IVariantField } from '../components/PollForm/types';

import { apiCreateQuestion, apiCreateVariant, apiDeleteQuestion, apiDeleteVariant, apiUpdateQuestion, apiUpdateVariant } from './api';
import { assignWeights } from './utils';

export const createVariants = (
  questionId: number,
  variants: IVariantField[],
) => Promise.allSettled(
  variants.map(
    (variant) => apiCreateVariant(questionId, variant),
  ),
);

export const deleteVariants = (
  variants: IExtendedAnswer[],
) => Promise.allSettled(
  variants.map(({ id }) => apiDeleteVariant(id)),
);

export const updateVariants = (
  questionId: number,
  originalVariants: IExtendedAnswer[],
  updatedVariants: IVariantField[],
) => {
  const [oldVariants, newVariants] = partition<IVariantField, IAnswer>(
    updatedVariants,
    (variant): variant is IAnswer => 'id' in variant,
  );

  const variantsToDelete = differenceBy(
    originalVariants,
    oldVariants,
    (question) => question.id,
  );

  const variantsToUpdate = oldVariants.filter(
    (variant) => !isEqual(
      originalVariants.find(({ id }) => id === variant.id),
      variant,
    ),
  );

  return Promise.allSettled([
    createVariants(questionId, newVariants),
    deleteVariants(variantsToDelete),
    ...variantsToUpdate.map(apiUpdateVariant),
  ]);
};

const createSingleQuestion = async (pollId: number, question: IQuestionField) => {
  const { data: { id } } = await apiCreateQuestion(pollId, question);

  return createVariants(id, question.variants);
};

export const createQuestions = (
  pollId: number,
  questions: IQuestionField[],
) => Promise.allSettled(
  questions.map(
    (question) => createSingleQuestion(pollId, question),
  ),
);

export const deleteQuestions = (
  questions: IExtendedQuestion[],
) => Promise.allSettled(
  questions.map(({ id }) => apiDeleteQuestion(id)),
);

export const updateSingleQuestion = (
  poll: IExtendedPoll,
  question: IQuestion,
) => {
  const { variants, ...questionFields } = question;
  const { variants: originalVariants, ...originalQuestionFields } = poll.questions.find(
    ({ id }) => id === question.id,
  ) as IExtendedQuestion;

  const requests = [];

  if (!isEqual(questionFields, originalQuestionFields)) {
    requests.push(apiUpdateQuestion(question));
  }

  if (!isEqual(variants, originalVariants)) {
    requests.push(
      updateVariants(question.id, originalVariants, variants),
    );
  }

  return Promise.allSettled(requests);
};

export const updateQuestions = (
  poll: IExtendedPoll,
  questionFields: IQuestionField[],
) => {
  const questions = assignWeights(questionFields);

  const [oldQuestions, newQuestions] = partition<IQuestionField, IQuestion>(
    questions,
    (question): question is IQuestion => 'id' in question,
  );

  const questionsToDelete = differenceBy(
    poll.questions,
    oldQuestions,
    (question) => question.id,
  );

  return Promise.allSettled([
    createQuestions(poll.id, newQuestions),
    deleteQuestions(questionsToDelete),
    ...oldQuestions.map((question) => updateSingleQuestion(poll, question)),
  ]);
};
