import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiSendFeedback } from '@core/store/modules/feedback/api';
import { ISendFeedbackPayloadApiModel } from '@core/store/modules/feedback/models';
import { IGenericMetaState, TRootState } from '@core/store/types';
import { setFulfilledMetaState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

interface IFeedbackState {
  meta: IGenericMetaState;
}

export const sendFeedback = createAsyncThunk<void, ISendFeedbackPayloadApiModel>(
  'feedback/send',
  async (payload, { rejectWithValue }) => {
    try {
      const data = {
        ...payload,
        category: payload.category.id,
        department: payload.department.id,
      };

      const payloadFormData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (key === 'files' && value) {
          value.forEach((file: File) => {
            payloadFormData.append(key, file);
          });
        } else if (value) {
          payloadFormData.append(key, value);
        }
      });

      await apiSendFeedback(payloadFormData);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IFeedbackState = {
  meta: {
    fetchLoading: false,
    fetchSuccess: false,
    fetchError: false,
  },
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendFeedback.pending, setPendingState);
    builder.addCase(sendFeedback.fulfilled, setFulfilledMetaState);
    builder.addCase(sendFeedback.rejected, setRejectedState);
  },
});

export const selectFeedbackMeta = ({ feedback }: TRootState): IGenericMetaState => feedback.meta;

export default feedbackSlice.reducer;
