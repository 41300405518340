export function getLocalStorageItem<T>(key: string) {
  try {
    const item = localStorage.getItem(key);

    return item ? JSON.parse(item) as T : null;
  } catch (e) {
    return null;
  }
}

export function setLocalStorageItem(key: string, value: any) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
