import { IEncyclopedia } from '@core/models/encyclopedia';
import { IMyDictionaryItem } from '@core/models/myDictionary';
import http from '@core/services/Http';

import { IApiFetchParams } from './types';

export const apiFetchEncyclopedia = async (params: IApiFetchParams): Promise<IEncyclopedia[]> => {
  const { data } = await http.get<IEncyclopedia[]>('encyclopedia-public/term', params, { abort: true });
  return data;
};

export const apiFetchPrivateEncyclopedia = async (params: IApiFetchParams): Promise<IEncyclopedia[]> => {
  const { data } = await http.get<IEncyclopedia[]>('encyclopedia/term', params, { abort: true });
  return data;
};

export const apiFetchMyDictionary = async (params: IApiFetchParams): Promise<IMyDictionaryItem[]> => {
  const { data } = await http.get<IMyDictionaryItem[]>('encyclopedia/term/favorite', params, { abort: true });

  return data;
};

export const apiAddMyDictionaryItem = async (id: IMyDictionaryItem['id']): Promise<void> => {
  await http.post(`encyclopedia/term/favorite/${id}`, {});
};

export const apiDeleteMyDictionaryItem = async (id: IMyDictionaryItem['id']): Promise<void> => {
  await http.delete(`encyclopedia/term/favorite/${id}`);
};
