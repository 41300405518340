import { IGenericMetaState } from '@core/store/types';

import { IDynamicTable, IDynamicTableApi, TDynamicTableMobile } from '@features/dynamicTable/types';

export interface IAntiCrisisMonitoringApi extends IDynamicTableApi {
  family: EAntiCrisisMonitoringTables;
  footnotes: string[];
}

export interface IAntiCrisisMonitoringData {
  family: EAntiCrisisMonitoringTables;
  date: string | null;
  name: string | null;
  footnotes: string[];
  desktopData: IDynamicTable | null;
  mobileData: TDynamicTableMobile | null;
}

export interface IFetchAntiCrisisMonitoringParams {
  family: EAntiCrisisMonitoringTables;
}

export enum EAntiCrisisMonitoringTables {
  MORTGAGE = 'anti_crisis_mortgage',
  SUPPORT_PROGRAMS = 'anti_crisis_support_programs',
  OFFERS = 'anti_crisis_supply',
  DEMAND = 'anti_crisis_demand',
  BUILDING_MATERIALS = 'anti_crisis_building_materials_prices',
}

export type THousingPolicyColor = 'red' | 'green' | 'yellow';

interface IRegion {
  name: string;
  value: string;
}

export interface IHousingPolicyTile {
  value: string;
  color: THousingPolicyColor;
}

interface IValue {
  value: string;
}

export interface IAverage {
  value: string;
  color?: THousingPolicyColor;
}

export interface IBorder {
  top: string;
  bottom: string;
}

interface IHousingPolicyCommonItem {
  date: string;
  average: IAverage;
  ijs?: IValue;
  mkd?: IValue;
  tiles: IHousingPolicyTile[];
  borders: IBorder,
  regions: {
    laggards: IRegion[];
    leaders: IRegion[];
  };
}

export interface IAntiCrisisMonitoringHousingPolicy {
  az: IHousingPolicyCommonItem & {
    meters: IValue;
    people: IValue;
  };
  ipoteka: IHousingPolicyCommonItem & {
    credits: IValue;
  };
  np2: IHousingPolicyCommonItem & {
    commissioned: IValue;
  };
  pbobj: IHousingPolicyCommonItem & {
    objects: IValue;
  };
}

export type IAntiCrisisMonitoringState = {
  data: { [key in EAntiCrisisMonitoringTables]: IAntiCrisisMonitoringData } & {
    housingPolicy: IAntiCrisisMonitoringHousingPolicy | null
  };
  meta: { [key in EAntiCrisisMonitoringTables]: IGenericMetaState } & {
    housingPolicy: IGenericMetaState;
  }
};
