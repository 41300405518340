import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { useDispatch } from 'react-redux';

import { isDevelopment } from '@core/utils/env';

import rootReducer from './rootReducer';

const isDev = isDevelopment();

const createStore = () => configureStore({ reducer: rootReducer, devTools: isDev });

export type TStore = ReturnType<typeof createStore>;
export type AppDispatch = TStore['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const wrapper = createWrapper(createStore, { debug: isDev });
