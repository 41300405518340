import { IGenericMetaState } from '@core/store/types';

import { IPagination } from '@features/materials/components/Discussions/model';
import { IDiscussion, IDiscussionFile, IUserShortcut } from '@features/materials/components/Discussions/types';

export interface ICommentsState {
  data: ICommentsList;
  params: IFetchCommentsParams;
  meta: IGenericMetaState;
  editMeta: IGenericMetaState;
  deleteMeta: IGenericMetaState;
}

export interface ICommentEditPayload {
  discussionId: IDiscussion['id'];
  text: IComment['text'];
  file?: File;
}

export interface IComment {
  id: number;
  createdAt: string; // 2022-02-21T11:27:11.770Z
  createdBy: IUserShortcut; // сокращенный объект IUser (id + ФИО)
  text: string; // текст комментария
  files: IDiscussionFile[]; // прикрепленные файлы (на момент реализации задачи ожидается только 1)
}

export type ICommentsList = IComment[];

export interface IFetchCommentsParams {
  discussionId: IDiscussion['id'] | null;
  sorting?: ECommentSorting;
  pagination: IPagination;
}

export enum ECommentSorting {
  NEW = 'new',
  OLD = 'old',
}
