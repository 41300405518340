import { ECollections } from '@core/store/constants';
import { createGenericCollectionSlice } from '@core/store/modules/collections/utils/slice';

import { ICollectionModel } from '@features/collection/models';

const { slice, thunks, selectors } = createGenericCollectionSlice<ICollectionModel, any>({
  collection: ECollections.COLLECTION,
});

const {
  fetchGetCollectionThunk: fetchGetCollection,
  fetchCreateItemInCollectionThunk: fetchCreateItemInCollection,
  fetchUpdateItemInCollectionThunk: fetchUpdateInCollection,
  fetchDeleteItemInCollectionThunk: fetchDeleteItemInCollection,
} = thunks;

const { selectSliceState: selectCollectionState } = selectors;

export {
  fetchGetCollection,
  fetchCreateItemInCollection,
  fetchUpdateInCollection,
  fetchDeleteItemInCollection,
  selectCollectionState,
};

export default slice.reducer;
