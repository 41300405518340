import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authorize, parseUser, removeJWT } from '@core/services/Auth';

import { selectJWTTokens, setUser } from '@features/authentication/store';

import { IAuthorizationProps } from './types';

const Authorization = (props: IAuthorizationProps) => {
  const { children } = props;

  const { access, refresh } = useSelector(selectJWTTokens);
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO: Удаляем у все пользователей  токены из localStorage;
    removeJWT();

    if (access && refresh) {
      const user = parseUser(access);
      dispatch(setUser(user));
      authorize(access, refresh);
    }
  }, [dispatch, access, refresh]);

  return <>{children}</>;
};

export default Authorization;
