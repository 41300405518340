import { cloneDeep } from 'lodash';

import { IDynamicTableApi } from '@features/dynamicTable/types';

/**
 * Добавляет нумерацию ряда каждого субъекта РФ в светофоре
 * @param originData - все данные по светофорам
 * @param hasDistrictFilter - выбран ли фильтр по региону
 */
export const addNumToRows = (originData: IDynamicTableApi, hasDistrictFilter: boolean): IDynamicTableApi => {
  const data = cloneDeep(originData);

  // нужно пропустить все ряды, которые являются заголовками таблицы
  const firstNonHeaderIndex = data.rows.findIndex((row) => !row.cells[0].header);
  // если фильтр не выбран, приходит один лишний ряд "Российская Федерация" - его также нужно пропустить
  const startIndex = hasDistrictFilter ? firstNonHeaderIndex : firstNonHeaderIndex + 1;

  for (let i = startIndex; i < data.rows.length; i++) {
    const row = data.rows[i];
    row.num = String(i + 1 - startIndex);
  }

  return data;
};
