import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { INews } from '@core/models/news';
import http from '@core/services/Http';
import { ENameSpaces } from '@core/store/constants';
import { IGenericMetaState, TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { setFulfilledState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

export const fetchPublicNews = createAsyncThunk<INews, INews['id']>(
  `${ENameSpaces.PUBLIC_NEWS_ITEM}/get`,
  async (newsId, { rejectWithValue }) => {
    try {
      const { data } = await http.get<INews>(`news-public/${newsId}`);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

interface IPublicNewsItemState {
  data: INews | null;
  meta: IGenericMetaState;
}

const initialState: IPublicNewsItemState = {
  data: null,
  meta: initialMeta,
};

const publicNewsItemSlice = createSlice({
  name: ENameSpaces.PUBLIC_NEWS_ITEM,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPublicNews.pending, setPendingState);
    builder.addCase(fetchPublicNews.fulfilled, setFulfilledState);
    builder.addCase(fetchPublicNews.rejected, setRejectedState);
  },
});

export const selectPublicNewsItem = ({ publicNewsItem }: TRootState): IPublicNewsItemState => publicNewsItem;

export default publicNewsItemSlice.reducer;
