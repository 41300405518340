export type TDashboardDataState = TDashboardApiModel;

export type TDashboardApiModel = IDashboardProject[] | undefined;

export type TDashboardProjectTypes = 'national' | 'federal';

export interface IDashboardProjectApi {
  id: number,
  type: TDashboardProjectTypes,
  name: string,
  subtitle?: string,
  budgetLBOWithBOPercent: number,
  budgetLBOWithBO: number,
  budgetBA: number,
  implYearPlanPercent: number,
  implementation: number,
  implYearPlan: number,
  implMonthPlanPercent: number,
  implMonthPlan: number,
  implPhysycalIndicator: string,
  implPhysycalUnit: string,
  implPhysycalPercent: number,
  implPhysycalActual: number,
  implPhysycalPlan: number,
  projects: IDashboardProject[],
}

export enum ENationalProjectKeys {
  URBAN_ENVIRONMENT = 'urbanEnvironment',
  SAFE_ROADS = 'safeRoads',
  MASTER_PLAN = 'masterPlan',
}

export interface IDashboardProject extends IDashboardProjectApi {
  nationalProjectKey?: ENationalProjectKeys;
  date: string | null; // 2021-11-25
}

export interface IUploadDashboardPayloadApiModel {
  file: File;
}
