import { SliceCaseReducers } from '@reduxjs/toolkit';

import { EEntities } from '@core/store/constants';
import { createGenericEntitySlice } from '@core/store/modules/entities/utils/slice';
import { IGenericState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';

import { mapTable } from '@features/dynamicTable/components/DesktopView/mappers';
import { mapMobileData } from '@features/dynamicTable/components/MobileView/mappers';
import { IDynamicTableApi } from '@features/dynamicTable/types';
import { addNumToRows } from '@features/svetofors/DetailingSvetofor/store/mappers';
import { IDetailingSvetoforData } from '@features/svetofors/DetailingSvetofor/store/types';

const { slice, thunks, selectors } = createGenericEntitySlice<IDetailingSvetoforData, SliceCaseReducers<IGenericState<IDetailingSvetoforData>>>({
  reducers: {
    resetState(state) {
      state.data = null;
      state.meta = initialMeta;
    },
  },
  entity: EEntities.DETAILING_SVETOFOR,
  thunksConfig: {
    fetchGetEntityThunk: {
      adapter: (originData: IDynamicTableApi): IDetailingSvetoforData => {
        const hasDistrictFilter = Boolean(originData.district);
        const data = addNumToRows(originData, hasDistrictFilter);

        return {
          desktopData: mapTable(data),
          mobileData: mapMobileData(data),
          name: data.name || null,
          districts: data.districts || null,
          date: data.date || null,
        };
      },
    },
  },
});

const {
  fetchGetEntityThunk: fetchDetailingSvetofor,
} = thunks;

const {
  selectSliceState: selectDetailingSvetofor,
} = selectors;

const { resetState } = slice.actions;

export {
  fetchDetailingSvetofor,
  selectDetailingSvetofor,
  resetState,
};

export default slice.reducer;
