import http from '@core/services/Http';
import { EXTENDED_TIMEOUT } from '@core/services/Http/Http';

import { IUser } from '@features/authentication/models';

import { IAggregatedSvetoforApi, ISvetoforApi } from './types';

export const apiUploadSvetoforsProjects = async (payload: FormData): Promise<void> => {
  await http.post('indicator', payload, {
    config: {
      timeout: EXTENDED_TIMEOUT,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  });
};

export const apiFetchSvetoforsCollection = async (regionId: IUser['region']['id']): Promise<ISvetoforApi[]> => {
  const { data } = await http.get<ISvetoforApi[]>('indicator', { regionId });
  return data;
};

export const apiFetchSvetoforsAggregatedCollection = async (): Promise<IAggregatedSvetoforApi[]> => {
  const { data } = await http.get<IAggregatedSvetoforApi[]>('indicator/aggregated');
  return data;
};
