import http from '@core/services/Http';
import { IFileInfo } from '@core/store/modules/materials/types';

interface IUploadSingleFileArg {
  title?: string;
  description?: string;
  isPublic: boolean;
  type: string;
  file: File
}

interface ISession {
  id: string;
}

export async function uploadSingleFile(arg: IUploadSingleFileArg) {
  const payload = Object.entries(arg).reduce((formData, [key, value]) => {
    formData.append(key, value);

    return formData;
  }, new FormData());

  const { data } = await http.post<ISession>('file/session', {});
  const res = await http.post<IFileInfo>(`file/single?session=${data.id}`, payload, {
    config: {
      timeout: 0,
    },
  });

  return res;
}
