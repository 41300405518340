import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { IBaseListModel, IBaseListModelParams } from '@core/models/list';
import http from '@core/services/Http';
import { ECollections, ENameSpaces } from '@core/store/constants';
import { TRootState, TThunkConfig } from '@core/store/types';

enum EThunkTypes {
  GET_COLLECTION = 'getCollection',
  GET_NEXT_PAGE_COLLECTION = 'getNextPageCollection',
  CREATE_ITEM = 'createItem',
  UPDATE_ITEM = 'updateItem',
  DELETE_ITEM = 'deleteItem',
}

const createGenericCollectionThunk = <Returned, ThunkArg = void>({
  collection,
  thunkType,
  fetchCallback,
  thunkConfig = {},
}: {
  collection: ECollections;
  thunkType: EThunkTypes;
  fetchCallback: (arg: ThunkArg) => Promise<AxiosResponse<Returned>>,
  thunkConfig?: TThunkConfig,
}) => {
  const { adapter } = thunkConfig;

  return createAsyncThunk<Returned, ThunkArg>(
    `collections/${collection}/${thunkType}`,
    async (arg, { rejectWithValue }) => {
      try {
        const { data } = await fetchCallback(arg);

        return adapter ? adapter(data) : data;
      } catch (error) {
        if (!error?.response) {
          throw error;
        }

        return rejectWithValue(error.response.data);
      }
    },
    { condition: (arg, { getState }) => !(getState() as TRootState)?.[ENameSpaces.COLLECTIONS][collection]?.meta?.fetchLoading },
  );
};

export const createFetchGetCollectionThunk = <T>(collection: ECollections, thunkConfig: TThunkConfig = {}) => {
  const { endpoint, headers = {} } = thunkConfig;

  const fetchCallback = (params: IBaseListModelParams) => http.get<IBaseListModel<T>>(
    endpoint || `/${collection}`,
    params,
    { config: { headers } },
  );

  return createGenericCollectionThunk<IBaseListModel<T>, IBaseListModelParams>({
    collection,
    thunkType: EThunkTypes.GET_COLLECTION,
    fetchCallback,
    thunkConfig,
  });
};

export const createFetchGetNextPageCollectionThunk = <T>(collection: ECollections, thunkConfig: TThunkConfig = {}) => {
  const { endpoint, headers = {} } = thunkConfig;

  const fetchCallback = (params: IBaseListModelParams) => http.get<IBaseListModel<T>>(endpoint || `/${collection}`, params, { config: { headers } });

  return createGenericCollectionThunk({
    collection,
    thunkType: EThunkTypes.GET_NEXT_PAGE_COLLECTION,
    fetchCallback,
    thunkConfig,
  });
};

export const createFetchCreateItemInCollectionThunk = <T>(collection: ECollections, thunkConfig: TThunkConfig = {}) => {
  const { endpoint, headers = {} } = thunkConfig;

  const fetchCallback = (item: Partial<T>) => http.post<T>(
    endpoint || `/${collection}`,
    item,
    { config: { headers } },
  );

  return createGenericCollectionThunk<T, Partial<T>>({
    collection,
    thunkType: EThunkTypes.CREATE_ITEM,
    fetchCallback,
    thunkConfig,
  });
};

export const createFetchUpdateItemInCollectionThunk = <T extends { id: string | number }>(
  collection: ECollections,
  thunkConfig: TThunkConfig = {},
) => {
  const { endpoint, headers = {} } = thunkConfig;

  const fetchCallback = (item: Partial<T>) => http.put<T>(
    endpoint || `/${collection}/${item.id}`,
    item,
    { config: { headers } },
  );

  return createGenericCollectionThunk<T, Partial<T>>({
    collection,
    thunkType: EThunkTypes.UPDATE_ITEM,
    fetchCallback,
    thunkConfig,
  });
};

export const createFetchDeleteItemInCollectionThunk = <T extends { id: string | number }>(
  collection: ECollections,
  thunkConfig: TThunkConfig = {},
) => {
  const { endpoint, headers = {} } = thunkConfig;

  const fetchCallback = (item: T) => http.delete<T>(
    endpoint || `/${collection}/${item.id}`,
    {},
    { config: { headers } },
  );

  return createGenericCollectionThunk<T, T>({
    collection,
    thunkType: EThunkTypes.DELETE_ITEM,
    fetchCallback,
  });
};
