import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IMyDictionaryItem } from '@core/models/myDictionary';
import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import {
  getFulfilledMeta,
  getPendingMeta,
  getRejectedMeta,
  setPendingState,
  setRejectedState,
} from '@core/store/utils/stateSetters';

import {
  apiAddMyDictionaryItem,
  apiDeleteMyDictionaryItem,
  apiFetchMyDictionary,
} from '@features/encyclopedia/api';
import {
  IApiFetchParams,
} from '@features/encyclopedia/api/types';

import { DEFAULT_LIMIT } from '../constants';

import { IMyDictionaryState } from './types';

export const fetchMyDictionary = createAsyncThunk<IMyDictionaryItem[], IApiFetchParams>(
  'myDictionary/fetchItems',
  async (params, { rejectWithValue }) => {
    try {
      return await apiFetchMyDictionary(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchMyDictionaryNextPage = createAsyncThunk<IMyDictionaryItem[], IApiFetchParams>(
  'myDictionary/fetchNextPage',
  async (params, { rejectWithValue }) => {
    try {
      return await apiFetchMyDictionary(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addMyDictionaryItem = createAsyncThunk<IMyDictionaryItem['id'], IMyDictionaryItem['id']>(
  'myDictionary/add',
  async (id, { rejectWithValue }) => {
    try {
      await apiAddMyDictionaryItem(id);

      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteMyDictionaryItem = createAsyncThunk<IMyDictionaryItem['id'], IMyDictionaryItem['id']>(
  'myDictionary/delete',
  async (id, { rejectWithValue }) => {
    try {
      await apiDeleteMyDictionaryItem(id);

      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IMyDictionaryState = {
  data: [],
  isAllDataFetched: false,
  meta: initialMeta,
  nextPageMeta: initialMeta,
  addMeta: initialMeta,
  deleteMeta: initialMeta,
};

export const myDictionarySlice = createSlice({
  name: 'myDictionary',
  initialState,
  reducers: {
    resetMyDictionary: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMyDictionary.pending, setPendingState);
    builder.addCase(fetchMyDictionary.fulfilled, (state, { payload }) => {
      if (payload.length < DEFAULT_LIMIT) {
        state.isAllDataFetched = true;
      } else {
        state.isAllDataFetched = false;
      }

      state.data = payload;
      state.meta = getFulfilledMeta();
    });
    builder.addCase(fetchMyDictionary.rejected, setRejectedState);

    builder.addCase(fetchMyDictionaryNextPage.pending, (state) => {
      state.nextPageMeta = getPendingMeta();
    });
    builder.addCase(fetchMyDictionaryNextPage.fulfilled, (state, { payload }) => {
      state.nextPageMeta = getFulfilledMeta();

      if (payload.length < DEFAULT_LIMIT) {
        state.isAllDataFetched = true;
      }

      state.data = [...state.data, ...payload];
    });
    builder.addCase(fetchMyDictionaryNextPage.rejected, (state) => {
      state.nextPageMeta = getRejectedMeta();
    });

    builder.addCase(addMyDictionaryItem.pending, (state) => {
      state.addMeta = getPendingMeta();
    });
    builder.addCase(addMyDictionaryItem.fulfilled, (state) => {
      state.addMeta = getFulfilledMeta();
    });
    builder.addCase(addMyDictionaryItem.rejected, (state) => {
      state.addMeta = getRejectedMeta();
    });

    builder.addCase(deleteMyDictionaryItem.pending, (state) => {
      state.deleteMeta = getPendingMeta();
    });
    builder.addCase(deleteMyDictionaryItem.fulfilled, (state, { payload }) => {
      state.data = state.data.filter(({ id }) => id !== payload);
      state.deleteMeta = getFulfilledMeta();
    });
    builder.addCase(deleteMyDictionaryItem.rejected, (state) => {
      state.deleteMeta = getRejectedMeta();
    });
  },
});

export const { resetMyDictionary } = myDictionarySlice.actions;

export const selectMyDictionaryState = ({ myDictionary }: TRootState) => myDictionary;

export default myDictionarySlice.reducer;
