import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { IGenericMetaState, TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import {
  getFulfilledMeta,
  getPendingMeta,
  getRejectedMeta,
  setFulfilledMetaState,
  setPendingState,
  setRejectedState,
} from '@core/store/utils/stateSetters';

import {
  apiCreateDiscussion,
  apiEditDiscussion,
  apiFetchDiscussions,
} from '@features/materials/components/Discussions/api';
import {
  EDiscussionSorting,
  IDiscussionEditPayload,
  IDiscussionsState,
} from '@features/materials/components/Discussions/model';

import { IDiscussion } from './types';

export const fetchDiscussions = createAsyncThunk<{ data: IDiscussionsState['data'], params: IDiscussionsState['params'] }>(
  'discussions/fetchList',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { params, data } = (getState() as TRootState).discussions;
      const newData = await apiFetchDiscussions(params);
      const newParams = cloneDeep(params);
      newParams.pagination.isLastPage = newData.length === 0 || newData.length < params.pagination.limit;

      return {
        data: [...data, ...newData],
        params: newParams,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createDiscussion = createAsyncThunk<void, IDiscussionEditPayload>(
  'discussions/create',
  async (payload, { rejectWithValue }) => {
    try {
      const payloadFormData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        payloadFormData.append(key, value);
      });

      return await apiCreateDiscussion(payloadFormData);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const editDiscussion = createAsyncThunk<void, { id: IDiscussion['id'], payload: IDiscussionEditPayload }>(
  'discussions/edit',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const payloadFormData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        payloadFormData.append(key, value);
      });

      return await apiEditDiscussion(id, payloadFormData);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IDiscussionsState = {
  data: [],
  params: {
    sorting: EDiscussionSorting.NEW,
    pagination: {
      limit: 10,
      offset: 0,
      isLastPage: false,
    },
  },
  meta: initialMeta,
  editMeta: initialMeta,
};

export const discussionsSlice = createSlice({
  name: 'discussions',
  initialState,
  reducers: {
    resetState: () => initialState,
    setParams(state, action: PayloadAction<Partial<IDiscussionsState['params']>>) {
      state.params = { ...state.params, ...action.payload };
    },
    resetEditMeta(state) {
      state.editMeta = initialMeta;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDiscussions.pending, setPendingState);
    builder.addCase(fetchDiscussions.fulfilled, (state, action) => {
      setFulfilledMetaState(state);
      state.data = action.payload.data;
      state.params = action.payload.params;
    });
    builder.addCase(fetchDiscussions.rejected, setRejectedState);

    builder.addCase(createDiscussion.pending, (state) => {
      state.editMeta = getPendingMeta();
    });
    builder.addCase(createDiscussion.fulfilled, (state) => {
      state.editMeta = getFulfilledMeta();
    });
    builder.addCase(createDiscussion.rejected, (state) => {
      state.editMeta = getRejectedMeta();
    });

    builder.addCase(editDiscussion.pending, (state) => {
      state.editMeta = getPendingMeta();
    });
    builder.addCase(editDiscussion.fulfilled, (state) => {
      state.editMeta = getFulfilledMeta();
    });
    builder.addCase(editDiscussion.rejected, (state) => {
      state.editMeta = getRejectedMeta();
    });
  },
});

const { setParams, resetState, resetEditMeta } = discussionsSlice.actions;
export { setParams, resetState, resetEditMeta };

export const selectDiscussions = ({ discussions }: TRootState): IDiscussionsState => discussions;
export const selectParams = ({ discussions }: TRootState): IDiscussionsState['params'] => discussions.params;
export const selectEditDiscussionMeta = ({ discussions }: TRootState): IGenericMetaState => discussions.editMeta;

export const setSorting = createAsyncThunk<void, EDiscussionSorting>(
  'discussions/setParamsAndFetchAll',
  (sorting, { dispatch }) => {
    dispatch(resetState());
    dispatch(setParams({ sorting }));
    dispatch(fetchDiscussions());
  },
);

export const fetchNextPage = createAsyncThunk<void>(
  'discussions/fetchNextPage',
  (arg, { dispatch, getState }) => {
    const { isLastPage, offset, limit } = (getState() as TRootState).discussions.params.pagination;
    const pagination = {
      isLastPage,
      limit,
      offset: offset + limit,
    };
    dispatch(setParams({ pagination }));
    dispatch(fetchDiscussions());
  },
);

export default discussionsSlice.reducer;
