import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IMaterialVideo } from '@core/models/media';
import { ENameSpaces } from '@core/store/constants';
import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { setFulfilledState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

import { apiFetchVideoById, apiFetchPublicVideoById } from './api';
import { IFetchVideoArgs, IVideoItemState } from './types';

export const fetchVideoById = createAsyncThunk<IMaterialVideo, IFetchVideoArgs>(
  `${ENameSpaces.VIDEO_ARCHIVE}/fetchItem`,
  async ({ isPublic, id }, { rejectWithValue }) => {
    try {
      return await (
        isPublic
          ? apiFetchPublicVideoById(id)
          : apiFetchVideoById(id)
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IVideoItemState = {
  data: null,
  meta: initialMeta,
};

const videoItemSlice = createSlice({
  name: ENameSpaces.VIDEO_ITEM,
  initialState,
  reducers: {
    setVideoItem: (state, action: PayloadAction<IMaterialVideo | null>) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchVideoById.pending, setPendingState);
    builder.addCase(fetchVideoById.rejected, setRejectedState);
    builder.addCase(fetchVideoById.fulfilled, setFulfilledState);
  },
});

export const selectVideoItem = ({ videoItem }: TRootState) => videoItem;

export const { setVideoItem } = videoItemSlice.actions;

export default videoItemSlice.reducer;
