import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';

import http from '@core/services/Http';
import { EEntities, ENameSpaces } from '@core/store/constants';
import { TQueryParams } from '@core/store/modules/entities/utils/types';
import { TRootState, TThunkConfig } from '@core/store/types';

enum EThunkTypes {
  GET_ITEM = 'getItem',
  CREATE_ITEM = 'createItem',
  UPDATE_ITEM = 'updateItem',
  DELETE_ITEM = 'deleteItem',
}

const createGenericEntityThunk = <Returned, ThunkArg>({
  entity,
  thunkType,
  fetchCallback,
  thunkConfig = {},
}: {
  entity: EEntities,
  thunkType: EThunkTypes,
  fetchCallback: (arg: ThunkArg) => Promise<AxiosResponse<Returned>>,
  thunkConfig?: TThunkConfig,
}) => {
  const { adapter, schema } = thunkConfig;

  return createAsyncThunk<Returned, ThunkArg>(
    `entities/${entity}/${thunkType}`,
    async (arg, { rejectWithValue }) => {
      try {
        const { data: originData } = await fetchCallback(arg);

        const data = adapter ? adapter(originData) : originData;

        if (schema) {
          const { entities } = normalize([originData], [schema]);

          return entities[entity];
        }

        return data;
      } catch (error) {
        if (!error?.response) {
          throw error;
        }

        return rejectWithValue(error.response.data);
      }
    },
    { condition: (arg, { getState }) => !(getState() as TRootState)?.[ENameSpaces.ENTITIES][entity]?.meta?.fetchLoading },
  );
};

export const createFetchGetEntityThunk = <T>(entity: EEntities, thunkConfig: TThunkConfig = {}) => {
  const { endpoint, headers = {} } = thunkConfig;

  const fetchCallback = (params: any = {}) => http.get<T>(
    endpoint || `/${entity}`,
    params,
    { config: { headers } },
  );

  return createGenericEntityThunk<T, TQueryParams | void>({
    entity,
    thunkType: EThunkTypes.GET_ITEM,
    fetchCallback,
    thunkConfig,
  });
};

export const createFetchCreateEntityThunk = <T>(entity: EEntities, thunkConfig: TThunkConfig = {}) => {
  const { endpoint, headers = {} } = thunkConfig;

  const fetchCallback = (arg: Partial<T>) => http.post<T>(
    endpoint || `/${entity}`,
    arg,
    { config: { headers } },
  );

  return createGenericEntityThunk<T, Partial<T>>({
    entity,
    thunkType: EThunkTypes.CREATE_ITEM,
    fetchCallback,
    thunkConfig,
  });
};

export const createFetchUpdateEntityThunk = <T>(entity: EEntities, thunkConfig: TThunkConfig = {}) => {
  const { endpoint, headers = {} } = thunkConfig;

  const fetchCallback = (arg: Partial<T>) => http.put<T>(
    endpoint || `/${entity}`,
    arg,
    { config: { headers } },
  );

  return createGenericEntityThunk<T, Partial<T>>({
    entity,
    thunkType: EThunkTypes.UPDATE_ITEM,
    fetchCallback,
    thunkConfig,
  });
};

export const createFetchDeleteEntityThunk = <T>(entity: EEntities, thunkConfig: TThunkConfig = {}) => {
  const { endpoint, headers = {} } = thunkConfig;

  const fetchCallback = async () => http.delete<T>(
    endpoint || `/${entity}`,
    {},
    { config: { headers } },
  );

  return createGenericEntityThunk<T, void>({
    entity,
    thunkType: EThunkTypes.DELETE_ITEM,
    fetchCallback,
  });
};
