import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiFetchDictionary } from '@core/store/modules/feedbackDictionaries/api';
import {
  EDictionaryNames,
  IFeedbackDictionariesData,
  IFeedbackDictionariesState,
} from '@core/store/modules/feedbackDictionaries/types';
import { TRootState } from '@core/store/types';
import {
  setFulfilledState,
  setPendingState,
  setRejectedState,
} from '@core/store/utils/stateSetters';

export const fetchDictionaries = createAsyncThunk<IFeedbackDictionariesData | null>(
  'feedback/dictionary',
  async (arg, { rejectWithValue }) => {
    try {
      const [category, department] = await Promise.all([
        apiFetchDictionary(EDictionaryNames.CATEGORY),
        apiFetchDictionary(EDictionaryNames.DEPARTMENT),
      ]);

      const result = {
        [EDictionaryNames.CATEGORY]: category,
        [EDictionaryNames.DEPARTMENT]: department,
      };
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IFeedbackDictionariesState = {
  data: {
    [EDictionaryNames.CATEGORY]: [],
    [EDictionaryNames.DEPARTMENT]: [],
  },
  meta: {
    fetchLoading: false,
    fetchSuccess: false,
    fetchError: false,
  },
};

export const feedbackDictionariesSlice = createSlice({
  name: 'feedbackDictionaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDictionaries.pending, setPendingState);
    builder.addCase(fetchDictionaries.fulfilled, setFulfilledState);
    builder.addCase(fetchDictionaries.rejected, setRejectedState);
  },
});

export const selectDictionaries = ({ feedbackDictionaries }: TRootState): IFeedbackDictionariesState => feedbackDictionaries;

export default feedbackDictionariesSlice.reducer;
