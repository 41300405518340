import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Provider } from 'react-redux';

import { ModalProvider } from '@eisgs/modal';

import { DrawerProvider } from '@core/UI/Drawer/components/Provider';
import ErrorBoundary from '@core/components/ErrorBoundary';
import Authorization from '@core/services/Auth/components/Authorization';
import { wrapper } from '@core/store';

import '@core/styles/reset.css';
import '@core/styles/fonts.css';
import '@core/styles/global.css';

const ToastContainer = dynamic(import('@eisgs/toast').then((module) => module.ToastContainer), { ssr: false });

const App = (props: AppProps) => {
  const { Component, ...rest } = props;
  const { store, props: { pageProps } } = wrapper.useWrappedStore(rest);

  return (
    <>
      <Head>
        <meta
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
          name="viewport"
        />
        <meta content="ie=edge" httpEquiv="X-UA-Compatible" />
        <meta content="Официальный сайт строительного комплекса Российской Федерации. Мониторинг достижения цели 'Комфортная и безопасная среда для жизни'" name="description" />
        <title>стройкомплекс.рф</title>
      </Head>

      <ErrorBoundary>
        <Provider store={store}>
          <Authorization>
            <ModalProvider>
              <DrawerProvider>
                <Component {...pageProps} />
                <ToastContainer />
              </DrawerProvider>
            </ModalProvider>
          </Authorization>
        </Provider>
      </ErrorBoundary>
    </>
  );
};

export default App;
