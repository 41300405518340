import { getBackendUrl } from '@core/utils/env';
import { isBrowser } from '@core/utils/isBrowser';

import Http from './Http';

const baseUrl = isBrowser() ? '/api' : getBackendUrl();

const http = new Http(baseUrl);

export { Http };

export default http;
