import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EMediaItemType, IMaterialFile, TMediaItem } from '@core/models/media';
import { apiDeleteAlbum, apiDeleteMaterial, apiFetchMedia } from '@core/services/media/api';
import { IFetchMediaItemsArg, IFetchMoreMediaItemsArg } from '@core/services/media/types';
import { ENameSpaces } from '@core/store/constants';
import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { getFulfilledMeta, getPendingMeta, getRejectedMeta, setFulfilledMetaState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

import { downloadFile, isMaterial, isSameMediaItem } from '../utils';

import { createAlbum, updateAlbum } from './album';
import { IMediaState } from './types';
import { createVideo, updateVideo } from './video';

export const initialState: IMediaState = {
  data: [],
  filters: {},
  meta: initialMeta,
  createMeta: initialMeta,
  downloadMeta: initialMeta,
  updateMeta: initialMeta,
  deleteMeta: initialMeta,
  pagination: {
    fetchedAll: false,
    limit: 12,
    offset: 0,
  },
};

export const fetchMedia = createAsyncThunk<TMediaItem[], IFetchMediaItemsArg>(
  `${ENameSpaces.MEDIA}/fetch`,
  async (arg, { getState, rejectWithValue }) => {
    try {
      const rootState = getState() as TRootState;
      const { filters } = rootState[ENameSpaces.MEDIA];

      const { data } = await apiFetchMedia({ ...filters, ...arg });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchMoreMedia = createAsyncThunk<TMediaItem[], IFetchMoreMediaItemsArg>(
  `${ENameSpaces.MEDIA}/fetchMore`,
  async ({ limit }, { getState, rejectWithValue }) => {
    try {
      const rootState = getState() as TRootState;
      const { pagination, filters } = rootState[ENameSpaces.MEDIA];

      const { data } = await apiFetchMedia({
        limit,
        offset: pagination.offset + pagination.limit,
        ...filters,
      });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteMedia = createAsyncThunk<TMediaItem[], TMediaItem[]>(
  `${ENameSpaces.MEDIA}/delete`,
  async (itemsToDelete, { rejectWithValue }) => {
    try {
      await Promise.allSettled(itemsToDelete.map(
        (item) => (
          isMaterial(item)
            ? apiDeleteMaterial(item.id)
            : apiDeleteAlbum(item.id)
        ),
      ));

      return itemsToDelete;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const downloadMedia = createAsyncThunk<void, TMediaItem[]>(
  `${ENameSpaces.MEDIA}/download`,
  async (items, { rejectWithValue }) => {
    try {
      const files = items.reduce((acc, item) => {
        if (isMaterial(item)) {
          return [...acc, item.file];
        }

        return acc.concat(item.materials.map(({ file }) => file));
      }, [] as IMaterialFile[]);

      await Promise.allSettled(files.map(downloadFile));
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const mediaSlice = createSlice({
  name: ENameSpaces.MEDIA,
  initialState,
  reducers: {
    setIdOrName(state, action: PayloadAction<string>) {
      state.filters.idOrName = action.payload;
    },
    setType(state, action: PayloadAction<EMediaItemType>) {
      state.filters.type = action.payload;
    },
    setCreatedAt(state, action: PayloadAction<string>) {
      state.filters.createdAt = action.payload;
    },
    resetFilters(state) {
      state.filters = {};
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchMedia.pending, setPendingState);
    builder.addCase(fetchMedia.rejected, setRejectedState);
    builder.addCase(fetchMedia.fulfilled, (state, { payload, meta: { arg } }) => {
      state.data = payload;
      state.pagination = {
        fetchedAll: payload.length < arg.limit,
        ...arg,
      };

      setFulfilledMetaState(state);
    });

    builder.addCase(fetchMoreMedia.pending, setPendingState);
    builder.addCase(fetchMoreMedia.rejected, setRejectedState);
    builder.addCase(fetchMoreMedia.fulfilled, (state, { payload, meta: { arg } }) => {
      state.data = [...state.data, ...payload];
      state.pagination.fetchedAll = payload.length < arg.limit;
      state.pagination.offset += state.pagination.limit;
      state.pagination.limit = arg.limit;

      setFulfilledMetaState(state);
    });

    builder.addCase(deleteMedia.pending, (state) => {
      state.deleteMeta = getPendingMeta();
    });
    builder.addCase(deleteMedia.fulfilled, (state, { payload, meta: { arg } }) => {
      state.data = state.data.filter(
        (item) => !payload.some(
          (deletedItem) => isSameMediaItem(deletedItem, item),
        ),
      );
      state.pagination.offset -= arg.length;
      state.deleteMeta = getFulfilledMeta();
    });
    builder.addCase(deleteMedia.rejected, (state) => {
      state.deleteMeta = getRejectedMeta();
    });

    builder.addCase(downloadMedia.pending, (state) => {
      state.downloadMeta = getPendingMeta();
    });
    builder.addCase(downloadMedia.fulfilled, (state) => {
      state.downloadMeta = getFulfilledMeta();
    });
    builder.addCase(downloadMedia.rejected, (state) => {
      state.downloadMeta = getRejectedMeta();
    });

    builder.addCase(createAlbum.pending, (state) => {
      state.createMeta = getPendingMeta();
    });
    builder.addCase(createAlbum.fulfilled, (state) => {
      state.createMeta = getFulfilledMeta();
    });
    builder.addCase(createAlbum.rejected, (state) => {
      state.createMeta = getRejectedMeta();
    });

    builder.addCase(createVideo.pending, (state) => {
      state.createMeta = getPendingMeta();
    });
    builder.addCase(createVideo.fulfilled, (state) => {
      state.createMeta = getFulfilledMeta();
    });
    builder.addCase(createVideo.rejected, (state) => {
      state.createMeta = getRejectedMeta();
    });

    builder.addCase(updateAlbum.pending, (state) => {
      state.updateMeta = getPendingMeta();
    });
    builder.addCase(updateAlbum.fulfilled, (state) => {
      state.updateMeta = getFulfilledMeta();
    });
    builder.addCase(updateAlbum.rejected, (state) => {
      state.updateMeta = getRejectedMeta();
    });

    builder.addCase(updateVideo.pending, (state) => {
      state.updateMeta = getPendingMeta();
    });
    builder.addCase(updateVideo.fulfilled, (state) => {
      state.updateMeta = getFulfilledMeta();
    });
    builder.addCase(updateVideo.rejected, (state) => {
      state.updateMeta = getRejectedMeta();
    });
  },
});

type TSliceState = ReturnType<typeof mediaSlice.reducer>;

export const selectMedia = (rootState: TRootState): TSliceState => rootState[ENameSpaces.MEDIA];

export const { setIdOrName, setCreatedAt, setType, resetFilters } = mediaSlice.actions;

export default mediaSlice.reducer;
