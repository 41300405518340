import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IMaterialVideo } from '@core/models/media';
import { ENameSpaces } from '@core/store/constants';
import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { getFulfilledMeta, setFulfilledState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

import { DEFAULT_LIMIT } from '../constants';

import { apiFetchVideoArchive, apiFetchPublicVideoArchive } from './api';
import { IFetchVideoArchiveArgs, IVideoArchiveState } from './types';

export const fetchVideoArchive = createAsyncThunk<IMaterialVideo[], IFetchVideoArchiveArgs>(
  `${ENameSpaces.VIDEO_ARCHIVE}/fetch`,
  async ({ isPublic, ...params }, { rejectWithValue }) => {
    try {
      return await (
        isPublic
          ? apiFetchPublicVideoArchive(params)
          : apiFetchVideoArchive(params)
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchVideoArchiveNextPage = createAsyncThunk<IMaterialVideo[], Omit<IFetchVideoArchiveArgs, 'limit' | 'offset'>>(
  `${ENameSpaces.VIDEO_ARCHIVE}/fetchNextPage`,
  async ({ isPublic, ...args }, { getState, rejectWithValue }) => {
    try {
      const state = (getState() as TRootState)[ENameSpaces.VIDEO_ARCHIVE];

      const params = {
        ...args,
        limit: DEFAULT_LIMIT,
        offset: state.data.length,
      };

      return await (
        isPublic
          ? apiFetchPublicVideoArchive(params)
          : apiFetchVideoArchive(params)
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IVideoArchiveState = {
  data: [],
  fetchedAll: false,
  meta: initialMeta,
};

const videoArchiveSlice = createSlice({
  name: ENameSpaces.VIDEO_ARCHIVE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchVideoArchive.pending, setPendingState);
    builder.addCase(fetchVideoArchive.rejected, setRejectedState);
    builder.addCase(fetchVideoArchive.fulfilled, (state, { payload }) => {
      state.fetchedAll = payload.length < DEFAULT_LIMIT;
      setFulfilledState(state, { payload });
    });

    builder.addCase(fetchVideoArchiveNextPage.pending, setPendingState);
    builder.addCase(fetchVideoArchiveNextPage.rejected, setRejectedState);
    builder.addCase(fetchVideoArchiveNextPage.fulfilled, (state, { payload }) => {
      state.meta = getFulfilledMeta();
      state.data = [...state.data, ...payload];
      state.fetchedAll = payload.length < DEFAULT_LIMIT;
    });
  },
});

export const selectVideoArchive = ({ videoArchive }: TRootState) => videoArchive;

export default videoArchiveSlice.reducer;
