import React, { ReactNode } from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';

export type TTableColumn = {
  id: number;
  title?: React.ReactNode | string;
  render: (row: any, index?: number) => React.ReactNode | string;
  size?: number;
  sort?: Required<Pick<TSort, 'key'>>;
};

export interface ITableProps {
  /**
   * Колонки таблицы
   */
  columns: TTableColumn[];
  /**
   * Данные таблицы
   */
  data?: any[];
  /**
   * Кастомные стили контейнера компонента
   */
  styles?: FlattenSimpleInterpolation;
  /**
   * Объект сортировки
   */
  sort?: TSort;
  /**
   Обработчик события сортировки
   */
  onSort?: (sort: TSort) => void;
  /**
   Управление лоадером загрузки
   */
  loading?: boolean;
  /**
   * Дополнительный класс
   */
  className?: string;
  /**
   * Функция для рендера кастомной строки
   */
  renderRow?: (rowProps: IRenderRowArg) => ReactNode;
}

export interface ITableStylesProps extends Pick<ITableProps, 'styles'> {}

export interface IHeaderRowStylesProps {
  columnsSizes: string;
}

export interface IContentStylesProps {
  rowsAmount: number;
}

export interface IRowProps extends Pick<IHeaderRowStylesProps, 'columnsSizes'> {
  children?: ReactNode;
}

export interface IRenderRowArg extends IRowProps {
  row: any;
}

export declare type TSort = {
  key?: string;
  direction?: ESortDirection;
};

export enum ESortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
