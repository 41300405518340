import http from '@core/services/Http';

import {
  IAntiCrisisMonitoringApi,
  IAntiCrisisMonitoringHousingPolicy,
  IFetchAntiCrisisMonitoringParams,
} from '@features/antiCrisisMonitoring/store/types';

export const apiFetchAntiCrisisMonitoring = async (params: IFetchAntiCrisisMonitoringParams): Promise<IAntiCrisisMonitoringApi> => {
  const { data } = await http.get<IAntiCrisisMonitoringApi>('table/anti-crisis', params);
  return data;
};

export const apiFetchAntiCrisisMonitoringHousingPolicy = async () => {
  const { data } = await http.get<IAntiCrisisMonitoringHousingPolicy>('/indicator/widgets');
  return data;
};
