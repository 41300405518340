import { INews } from '@core/models/news';
import { ECollections } from '@core/store/constants';
import { createGenericCollectionSlice } from '@core/store/modules/collections/utils/slice';

const AMOUNT_OF_TOP_NEWS = 5;

const { slice, thunks, selectors } = createGenericCollectionSlice<INews, any>({
  collection: ECollections.LANDING_TOP_NEWS,
  thunksConfig: {
    fetchGetCollectionThunk: {
      endpoint: '/news-public',
      adapter: (response) => ({ items: response.slice(0, AMOUNT_OF_TOP_NEWS) }),
    },
  },
});

const { fetchGetCollectionThunk: fetchLandingTopNews } = thunks;

const { selectSliceState: selectLandingTopNews } = selectors;

export { fetchLandingTopNews, selectLandingTopNews };

export default slice.reducer;
