import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { apiFetchDashboardProjects, apiUploadDashboardProjects } from '@core/store/modules/dashboards/api';
import { IGenericMetaState, TRootState } from '@core/store/types';
import {
  setFulfilledState,
  setPendingState,
  setRejectedState,
} from '@core/store/utils/stateSetters';

import { ENationalProjectKeys, IDashboardProject, IUploadDashboardPayloadApiModel, TDashboardApiModel, TDashboardDataState } from './types';

interface IDashboardState {
  meta: IGenericMetaState;
  uploadMeta: IGenericMetaState;
  data: TDashboardDataState;
}

export const uploadDashboardProjects = createAsyncThunk<void, IUploadDashboardPayloadApiModel>(
  'dashboard/uploadProjects',
  async (payload, { rejectWithValue }) => {
    try {
      const payloadFormData = new FormData();
      payloadFormData.append('file', payload.file);

      await apiUploadDashboardProjects(payloadFormData);
    } catch (error) {
      return rejectWithValue('rejected');
    }
  },
);

const getNationalProjectKey = (nationalProject: IDashboardProject): ENationalProjectKeys | undefined => {
  if (nationalProject.name.includes('Жилье и городская среда')) {
    return ENationalProjectKeys.URBAN_ENVIRONMENT;
  } if (nationalProject.name.includes('Безопасные и качественные дороги')) {
    return ENationalProjectKeys.SAFE_ROADS;
  } if (nationalProject.name.includes('Комплексный план модернизации и расширения магистральной инфраструктуры')) {
    return ENationalProjectKeys.MASTER_PLAN;
  }
};

export const fetchDashboardProjects = createAsyncThunk<TDashboardApiModel>(
  'dashboard/projects',
  async (arg, { rejectWithValue }) => {
    try {
      const nationalProjects = await apiFetchDashboardProjects();
      nationalProjects?.forEach((nationalProject) => {
        nationalProject.nationalProjectKey = getNationalProjectKey(nationalProject);
        nationalProject.projects.forEach((project) => {
          project.nationalProjectKey = getNationalProjectKey(nationalProject);
        });
      });
      return nationalProjects;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IDashboardState = {
  meta: {
    fetchLoading: false,
    fetchSuccess: false,
    fetchError: false,
  },
  data: [],
  uploadMeta: {
    fetchLoading: false,
    fetchSuccess: false,
    fetchError: false,
  },
};

export const dashboardsSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardProjectsData(state, action: PayloadAction<TDashboardDataState>) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardProjects.pending, setPendingState);
    builder.addCase(fetchDashboardProjects.fulfilled, setFulfilledState);
    builder.addCase(fetchDashboardProjects.rejected, setRejectedState);

    builder.addCase(uploadDashboardProjects.pending, (state) => {
      state.uploadMeta.fetchLoading = true;
      state.uploadMeta.fetchSuccess = false;
      state.uploadMeta.fetchError = false;
    });
    builder.addCase(uploadDashboardProjects.fulfilled, (state) => {
      state.uploadMeta.fetchLoading = false;
      state.uploadMeta.fetchSuccess = true;
      state.uploadMeta.fetchError = false;
    });
    builder.addCase(uploadDashboardProjects.rejected, (state) => {
      state.uploadMeta.fetchLoading = false;
      state.uploadMeta.fetchSuccess = false;
      state.uploadMeta.fetchError = true;
    });
  },
});

export const selectDashboardProjects = ({ dashboards }: TRootState): IDashboardState => dashboards;
export const selectDashboardUploadMeta = ({ dashboards }: TRootState): IGenericMetaState => dashboards.uploadMeta;

export default dashboardsSlice.reducer;
