import { sortBy } from 'lodash';

import { IDistrict } from '@core/models/region';
import { ECollections } from '@core/store/constants';
import { createGenericCollectionSlice } from '@core/store/modules/collections/utils/slice';

const { slice, thunks, selectors } = createGenericCollectionSlice<IDistrict, any>({
  collection: ECollections.DISTRICTS,
  thunksConfig: {
    fetchGetCollectionThunk: {
      endpoint: '/district',
      adapter: (response) => ({
        items: sortBy(response, ['id']).map(
          (district: IDistrict) => (
            district.name === '-'
              ? { ...district, name: 'Новые территории' }
              : district
          ),
        ),
      }),
    },
  },
});

const { fetchGetCollectionThunk: fetchDistricts } = thunks;

const { selectSliceState: selectDistricts } = selectors;

export { fetchDistricts, selectDistricts };

export default slice.reducer;
