import { AxiosResponse } from 'axios';

import http from '@core/services/Http';
import { IUserLogApiParams, TUserLogApiModel } from '@core/store/modules/adminPanel/userLog/types';

import { IUser } from '@features/authentication/models';

export const apiFetchUserLog = async (params?: IUserLogApiParams): Promise<TUserLogApiModel> => {
  const { data } = await http.get<TUserLogApiModel>('user', params);
  return data;
};

export const apiDownloadUserLog = async (params?: IUserLogApiParams): Promise<{ data: Blob, headers: AxiosResponse['headers'] }> => {
  const { data, headers } = await http.get<Blob>(
    'user/xlsx',
    params,
    { config: { responseType: 'blob' } },
  );

  return { data, headers };
};

export const apiUploadUsers = async (payload: FormData): Promise<void> => {
  await http.post('user/upload', payload, {
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  });
};

export const apiBlockUser = async (id: IUser['id']): Promise<void> => {
  await http.delete(`user/${id}`);
};

export const apiUnlockUser = async (id: IUser['id']): Promise<void> => {
  await http.put(`user/${id}/revert`, null);
};

export const apiEditUser = async (user: IUser): Promise<void> => {
  await http.put(`user/${user.id}`, user);
};
