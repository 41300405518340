import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ESortDirection, TSort } from '@core/UI/Table/types';
import {
  apiBlockUser,
  apiDownloadUserLog,
  apiEditUser,
  apiFetchUserLog,
  apiUnlockUser,
  apiUploadUsers,
} from '@core/store/modules/adminPanel/userLog/api';
import {
  IUploadUsersApiModel,
  IUserLog,
  IUserLogApiParams,
  TUserLogApiModel,
  TUserLogDataState,
} from '@core/store/modules/adminPanel/userLog/types';
import { IGenericMetaState, TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import {
  getFulfilledMeta,
  getPendingMeta,
  getRejectedMeta,
  setFulfilledState,
  setPendingState,
  setRejectedState,
} from '@core/store/utils/stateSetters';
import { createDownloadLink, parseFilename } from '@core/utils/downloadFile';

import { IUser } from '@features/authentication/models';

interface IUserLogState {
  data: TUserLogDataState;
  params: {
    sort: TSort;
  };
  meta: IGenericMetaState;
  blockMeta: IGenericMetaState;
  downloadMeta: IGenericMetaState;
  uploadMeta: IGenericMetaState;
  editMeta: IGenericMetaState;
}

export const fetchUserLog = createAsyncThunk<TUserLogApiModel>(
  'userLog/fetch',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { params } = (getState() as TRootState).userLog;
      const apiParams: IUserLogApiParams = {};
      if (params.sort) {
        apiParams.sortingField = params.sort.key as keyof IUserLog;
        apiParams.sortingDirection = params.sort.direction;
      }

      return await apiFetchUserLog(apiParams);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const downloadUserLog = createAsyncThunk(
  'userLog/download',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { params } = (getState() as TRootState).userLog;
      const apiParams: IUserLogApiParams = {};
      if (params.sort) {
        apiParams.sortingField = params.sort.key as keyof IUserLog;
        apiParams.sortingDirection = params.sort.direction;
      }

      const { data, headers } = await apiDownloadUserLog(apiParams);
      createDownloadLink(new Blob([data]), parseFilename(headers['content-disposition']));
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const uploadUsers = createAsyncThunk<void, IUploadUsersApiModel>(
  'userLog/upload',
  async (args, { rejectWithValue }) => {
    try {
      const { file } = args;
      const payloadFormData = new FormData();
      payloadFormData.append('file', file);

      await apiUploadUsers(payloadFormData);
    } catch (error) {
      return rejectWithValue('');
    }
  },
);

export const blockUser = createAsyncThunk<void, IUser['id']>(
  'userLog/block',
  async (userId, { rejectWithValue }) => {
    try {
      await apiBlockUser(userId);
    } catch (error) {
      return rejectWithValue('');
    }
  },
);

export const unlockUser = createAsyncThunk<void, IUser['id']>(
  'userLog/unlock',
  async (userId, { rejectWithValue }) => {
    try {
      await apiUnlockUser(userId);
    } catch (error) {
      return rejectWithValue('');
    }
  },
);

export const editUser = createAsyncThunk<void, IUser>(
  'userLog/edit',
  async (user, { rejectWithValue }) => {
    try {
      await apiEditUser(user);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IUserLogState = {
  data: [],
  params: {
    sort: { key: 'lastLogin', direction: ESortDirection.DESC },
  },
  meta: initialMeta,
  blockMeta: initialMeta,
  downloadMeta: initialMeta,
  uploadMeta: initialMeta,
  editMeta: initialMeta,
};

export const userLogSlice = createSlice({
  name: 'userLog',
  initialState,
  reducers: {
    resetUploadMeta(state) {
      state.uploadMeta = initialMeta;
    },
    resetBlockMeta(state) {
      state.blockMeta = initialMeta;
    },
    resetEditMeta(state) {
      state.editMeta = initialMeta;
    },
    setParams(state, action: PayloadAction<Partial<IUserLogState['params']>>) {
      state.params = { ...state.params, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserLog.pending, setPendingState);
    builder.addCase(fetchUserLog.fulfilled, setFulfilledState);
    builder.addCase(fetchUserLog.rejected, setRejectedState);

    builder.addCase(downloadUserLog.pending, (state) => {
      state.downloadMeta = getPendingMeta();
    });
    builder.addCase(downloadUserLog.fulfilled, (state) => {
      state.downloadMeta = getFulfilledMeta();
    });
    builder.addCase(downloadUserLog.rejected, (state) => {
      state.downloadMeta = getRejectedMeta();
    });

    builder.addCase(uploadUsers.pending, (state) => {
      state.uploadMeta = getPendingMeta();
    });
    builder.addCase(uploadUsers.fulfilled, (state) => {
      state.uploadMeta = getFulfilledMeta();
    });
    builder.addCase(uploadUsers.rejected, (state) => {
      state.uploadMeta = getRejectedMeta();
    });

    builder.addCase(blockUser.pending, (state) => {
      state.blockMeta = getPendingMeta();
    });
    builder.addCase(blockUser.fulfilled, (state) => {
      state.blockMeta = getFulfilledMeta();
    });
    builder.addCase(blockUser.rejected, (state) => {
      state.blockMeta = getRejectedMeta();
    });

    builder.addCase(unlockUser.pending, (state) => {
      state.blockMeta = getPendingMeta();
    });
    builder.addCase(unlockUser.fulfilled, (state) => {
      state.blockMeta = getFulfilledMeta();
    });
    builder.addCase(unlockUser.rejected, (state) => {
      state.blockMeta = getRejectedMeta();
    });

    builder.addCase(editUser.pending, (state) => {
      state.editMeta = getPendingMeta();
    });
    builder.addCase(editUser.fulfilled, (state) => {
      state.editMeta = getFulfilledMeta();
    });
    builder.addCase(editUser.rejected, (state) => {
      state.editMeta = getRejectedMeta();
    });
  },
});

const { resetUploadMeta, resetBlockMeta, resetEditMeta, setParams } = userLogSlice.actions;
export { resetUploadMeta, resetBlockMeta, resetEditMeta, setParams };

export const selectUserLog = ({ userLog }: TRootState): IUserLogState => userLog;
export const selectUsersUploadMeta = ({ userLog }: TRootState): IGenericMetaState => userLog.uploadMeta;
export const selectBlockUserMeta = ({ userLog }: TRootState): IGenericMetaState => userLog.blockMeta;
export const selectEditUserMeta = ({ userLog }: TRootState): IGenericMetaState => userLog.editMeta;

export default userLogSlice.reducer;
