import http from '@core/services/Http';

import { IComment, ICommentsList, IFetchCommentsParams } from '@features/materials/components/Discussion/components/Comments/model';

export const apiFetchComments = async (params: IFetchCommentsParams): Promise<ICommentsList> => {
  const apiParams = {
    discussionId: params.discussionId,
    sorting: params.sorting,
    limit: params.pagination.limit,
    offset: params.pagination.offset,
  };

  const { data } = await http.get<ICommentsList>('discussion-comment', apiParams);
  return data;
};

export const apiCreateComment = async (payload: FormData): Promise<void> => {
  await http.post('discussion-comment', payload);
};

export const apiDeleteComment = async (id: IComment['id']): Promise<void> => {
  await http.delete(`discussion-comment/${id}`);
};
