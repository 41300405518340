import { IGenericMetaState } from '@core/store/types';

export enum EDictionaryNames {
  CATEGORY = 'category',
  DEPARTMENT = 'department',
}

export interface IFeedbackDictionariesState {
  meta: IGenericMetaState;
  data: IFeedbackDictionariesData;
}

export type TDictionaryData = IDictionaryItem[];

export interface IDictionaryItem {
  id: number;
  name: string;
}

export interface IFeedbackDictionariesData {
  [EDictionaryNames.CATEGORY]: TDictionaryData;
  [EDictionaryNames.DEPARTMENT]: TDictionaryData;
}
