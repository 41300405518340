import http from '@core/services/Http';
import {
  ISendFeedbackPayloadApiModel,
} from '@core/store/modules/feedback/models';

export const apiSendFeedback = async (payload: ISendFeedbackPayloadApiModel): Promise<void> => {
  await http.post('feedback', payload, {
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  });
};
