import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TRootState } from '@core/store/types';
import { initialMeta } from '@core/store/utils/createGenericState';
import { setFulfilledState, setPendingState, setRejectedState } from '@core/store/utils/stateSetters';

import { apiFetchDiscussionFiles } from '@features/materials/components/Discussion/components/Documents/api';
import {
  IDiscussionFilesState,
  IFetchDiscussionFilesParams,
  IFilesList,
} from '@features/materials/components/Discussion/components/Documents/model';

export const fetchDiscussionFiles = createAsyncThunk<IFilesList, IFetchDiscussionFilesParams>(
  'discussion/fetchFiles',
  async (params, { rejectWithValue }) => {
    try {
      return await apiFetchDiscussionFiles(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IDiscussionFilesState = {
  data: {
    discussion: [],
    comments: [],
  },
  meta: initialMeta,
};

export const discussionFilesSlice = createSlice({
  name: 'discussionFiles',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDiscussionFiles.pending, setPendingState);
    builder.addCase(fetchDiscussionFiles.fulfilled, setFulfilledState);
    builder.addCase(fetchDiscussionFiles.rejected, setRejectedState);
  },
});

const { resetState } = discussionFilesSlice.actions;
export { resetState };

export const selectDiscussionFilesState = ({ discussionFiles }: TRootState): IDiscussionFilesState => discussionFiles;

export default discussionFilesSlice.reducer;
