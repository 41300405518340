import contentDisposition from 'content-disposition';

export const createDownloadLink = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const parseFilename = (contentDispositionHeader: string = ''): string => {
  try {
    const { parameters } = contentDisposition.parse(contentDispositionHeader);
    return parameters.filename;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Wrong filename');
    return '';
  }
};

export const getFileUrl = (id: number, isMediaSrc: boolean = true) => `${isMediaSrc ? '/api/' : ''}file/download/${id}`;

export const getPublicFileUrl = (id: number, isMediaSrc: boolean = true) => `${isMediaSrc ? '/api/' : ''}file-public/download/${id}`;
