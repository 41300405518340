import { ESortDirection, TSort } from '@core/UI/Table/types';
import { IGenericMetaState } from '@core/store/types';

export type TFileListApiModel = IFileInfo[];

export interface IFileInfo {
  id: number,
  type: EFileType,
  name: string,
  size: number, // in bytes
  mimetype: string,
  createdAt: string, // '2021-12-06T10:35:09.838Z'
  title?: string;
  description?: string;
  isPublic?: boolean;
}

export interface IFetchMaterialsFilesParams {
  type: EFileType;
  sort?: TSort;
}

export interface IFileListApiParams {
  type: EFileType;
  sortingField?: keyof IFileInfo;
  sortingDirection?: ESortDirection;
}

export enum EFileType {
  COMMON = 'common',
  HEADQUARTERS = 'headquarters',
  NEWS = 'news',
  DECREE = 'decree',
  FEDERAL_LAW = 'federal_law',
  STUDY = 'study',
}

export interface IMaterialsState {
  meta: IGenericMetaState;
  uploadMeta: IGenericMetaState;
  editMeta: IGenericMetaState;
  deleteMeta: IGenericMetaState;
  data: {
    [EFileType.HEADQUARTERS]: IMaterialsFilesData;
    [EFileType.COMMON]: IMaterialsFilesData;
    [EFileType.STUDY]: IMaterialsFilesData;
    [EFileType.DECREE]: IMaterialsFilesData;
    [EFileType.FEDERAL_LAW]: IMaterialsFilesData;
    [EFileType.NEWS]: IMaterialsFilesData;
  };
}

export interface IMaterialsFilesData {
  data: IFileInfo[];
  params: {
    sort: TSort;
  }
}

export interface IUploadMaterialsFilesApiModel {
  files: File[];
  type: EFileType;
}

export interface IFetchCreateFileParams {
  type: EFileType;
  title: string;
  description: string;
  isPublic: boolean;
  file: File;
}

export interface IFetchUpdateFileParams {
  id: number;
  type: EFileType;
  title: string;
  description: string;
  isPublic: boolean;
}
