import { IRegion } from '@core/models/region';
import { ECollections } from '@core/store/constants';
import { createGenericCollectionSlice } from '@core/store/modules/collections/utils/slice';

const { slice, thunks, selectors } = createGenericCollectionSlice<IRegion, any>({
  collection: ECollections.REGIONS,
  thunksConfig: {
    fetchGetCollectionThunk: {
      endpoint: '/region',
      adapter: (response) => ({ items: response }),
    },
  },
});

const { fetchGetCollectionThunk: fetchRegions } = thunks;

const { selectSliceState: selectRegions } = selectors;

export { fetchRegions, selectRegions };

export default slice.reducer;
